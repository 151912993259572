<template>
  <div id='projects' class="projects-section">
      
    <div class="bg-white py-24 sm:py-24" style="background: #F8FAFC;">

      <div class="mx-auto max-w-7xl px-6 lg:px-8">

        <div class="mx-auto max-w-2xl text-center">
          <h2 class="text-4xl font-normal tracking-tight text-gray-900 sm:text-4xl font-lexend">Some of our projects</h2>
          <p class="mt-2 text-lg leading-8 text-gray-600 font-lexend">Learn how to grow your business with our expert advice.</p>
        </div>

        <div class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-y-12 gap-x-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">

          <article v-for="(project, index) in projects" :key='index' class="flex flex-col items-start justify-between">
            <div class="relative w-full">
              <img :src="getImgUrl(project.image)" alt="" class="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]">
              <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"></div>
            </div>
            <div class="max-w-xl">
              <div class="mt-3 -ml-2 flex items-center gap-x-4 text-xs">
                <!-- <time datetime="2020-03-16" class="text-gray-500">Mar 16, 2020</time> -->
                <span v-for="(tag, index) in project.tags" :key='index' class="relative z-10 rounded-full bg-gray-50 py-1.5 px-3 font-medium text-gray-600 hover:bg-gray-100">{{tag}}</span>
              </div>
              <div class="group relative">
                <h3 class="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                  <a href="#">
                    <span class="absolute inset-0"></span>
                    {{project.name}}
                  </a>
                </h3>
                <p class="mt-5 text-sm leading-6 text-gray-600 line-clamp-3" style="height: 70px;">{{project.description}}</p>
              </div>
              <div class="relative mt-2 flex items-center gap-x-2 text-blue-400">
                <a :href="project.link" target="_blank">Visit site</a> <i class="fa-solid fa-arrow-up-right-from-square text-sm"></i>
              </div>
            </div>
          </article>

        </div>

      </div>

    </div>


  </div>
</template>

<script>

export default {
  name: 'Projects-list',
  components: {

  },
  data () {
    return {
      projects: [
        { 
          name: 'Atmosphere TV', 
          description: 'Atmosphere TV is the leading alternative to cable TV for businesses in the world, with over 50,000 businesses worldwide.',
          link: 'https://www.atmosphere.tv/',
          image: 'screenshots/atmosphere-screenshot.jpg',
          tags: ['Marketing', 'Entertainment']
        },
        { 
          name: 'Rare Carat', 
          description: "Rare Carat, America's #1 source for unbiased advice on loose diamonds & diamond engagement rings.",
          link: 'https://www.rarecarat.com/',
          image: 'screenshots/rare-carat.jpg',
          tags: ['Marketing', 'E-commerce']
        },
        { 
          name: 'Medosync', 
          description: "MedoSync stops these leakages by creating the invoice in real time and ensures hospitals get paid in full.",
          link: 'https://bshs.medosync.com/',
          image: 'screenshots/medosync.png',
          tags: ['Healthcare', 'Payment processing', 'Automation']
        },
        { 
          name: 'Save a Child Challenge', 
          description: "In the past six years of existence, O.U.R has rescued 4,100 victims and assisted in the arrests of more than 2,300 traffickers around the world. The partners we are empowering have collectively helped rescue the lives of more than 10,000 survivors who were enslaved, exploited or at risk",
          link: 'https://saveachildchallenge.com/invite-code',
          image: 'screenshots/saveachild.jpg',
          tags: ['Charity ', 'Fundraiser']
        },
        { 
          name: 'Creatorly / Brain Dump', 
          description: "System that takes the guesswork out of organizing your ideas...so you can create market-ready products fast.",
          link: 'https://creatorly.com/jointoday-1',
          image: 'screenshots/braindump.jpg',
          tags: ['Organize ', 'Planing']
        },
        { 
          name: 'XTM Payroll', 
          description: "XTM’s payroll solution allows businesses to pay their employees at any frequency. Gone are the days when bi-weekly pay was the only option.",
          link: 'https://www.xtminc.com/payroll',
          image: 'screenshots/xtm.jpg',
          tags: ['Payment processing ', 'Marketing']
        },
        { 
          name: 'Webprax Meeting Platform', 
          description: "webPRAX is the video consultation platform, especially for therapy and advice.",
          link: 'https://webprax.de/',
          image: 'screenshots/webprax.jpg',
          tags: ['Healthcare', 'Therapy', 'Advice']
        }

      ]
    }
  },

  methods: {
    getImgUrl(pic) {
        return require('@/assets/' + pic)
    }
  },

  mounted () {
    
  }
}
</script>

<style lang="scss" scoped>

  .projects-section {
    overflow: auto;
    background: red;
  }

  .font-lexend {
    font-family: 'Lexend', sans-serif;  
  }

  .font-inter {
    font-family: 'Inter', sans-serif;
  }

</style>