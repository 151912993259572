<template>
  <div class="partner">
    
    <div class="bg-white">
      <header class="absolute inset-x-0 top-0 z-50">
        <div class="mx-auto max-w-7xl">
          <div class="px-6 pt-6 lg:max-w-2xl lg:pl-8 lg:pr-0">
            <nav class="flex items-center justify-between lg:justify-start" aria-label="Global">
              <a href="/" class="-m-1.5 p-1.5">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                </svg>
              </a>
            </nav>
          </div>
        </div>
      </header>

      <div class="relative">
        <div class="mx-auto max-w-7xl">
          <div class="relative z-10 pt-12 lg:w-full lg:max-w-2xl">
            <svg class="absolute inset-y-0 right-8 hidden h-full w-80 translate-x-1/2 transform fill-white lg:block" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
              <polygon points="0,0 90,0 50,100 0,100" />
            </svg>

            <div class="relative px-6 py-8 sm:py-40 lg:px-8 lg:py-56 lg:pr-0">
              <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
                <h1 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Zima Blue Software Partner Program</h1>
                <p class="mt-6 text-lg leading-7 text-gray-600">Maximize your earning potential by joining our partner program. Connect us with clients and earn up to 15% of the talent contract value. Help us find the perfect matches for our top-notch outsourcing talent and share in the success of each successful placement.</p>
                <div class="mt-6 flex items-center gap-x-6">
                  <a href="#works" class="text-sm font-semibold leading-6 text-gray-900">Learn more <span aria-hidden="true">→</span></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img class="aspect-[3/2] object-cover lg:aspect-auto lg:h-full lg:w-full" src="https://images.unsplash.com/photo-1521737711867-e3b97375f902?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1587&q=80" alt="">
        </div>
      </div>
    </div>



    <!-- SECTION -->

    <div class="bg-gray-900 py-24 sm:py-32">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl lg:text-center">
          <p class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Unlimited Earnings Partner Program</p>
          <p class="mt-6 text-lg leading-8 text-gray-300">Enhance your revenue stream with our partner program, offering the following exceptional benefits</p>
        </div>
        <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            <div class="flex flex-col">
              <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-white">
                <svg class="h-5 w-5 flex-none text-indigo-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M5.5 17a4.5 4.5 0 01-1.44-8.765 4.5 4.5 0 018.302-3.046 3.5 3.5 0 014.504 4.272A4 4 0 0115 17H5.5zm3.75-2.75a.75.75 0 001.5 0V9.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0l-3.25 3.5a.75.75 0 101.1 1.02l1.95-2.1v4.59z" clip-rule="evenodd" />
                </svg>
                Up to 15% Per Contract
              </dt>
              <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-300">
                <p class="flex-auto">Receive monthly payments, earning up to 15% for each contract you facilitate.</p>
              </dd>
            </div>
            <div class="flex flex-col">
              <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-white">
                <svg class="h-5 w-5 flex-none text-indigo-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clip-rule="evenodd" />
                </svg>
                Unlimited Earnings
              </dt>
              <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-300">
                <p class="flex-auto">Enjoy the freedom of unlimited contract opportunities, expanding your earning potential</p>
              </dd>
            </div>
            <div class="flex flex-col">
              <dt class="flex items-center gap-x-3 text-base font-semibold leading-7 text-white">
                <svg class="h-5 w-5 flex-none text-indigo-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z" clip-rule="evenodd" />
                </svg>
                Earn Continuously
              </dt>
              <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-300">
                <p class="flex-auto">Benefit from continuous revenue; once a client is connected, receive funds indefinitely, until the contract is terminated</p>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>


    <!-- SECTION -->


    <!-- SECTION -->

    <div id="works" class="relative isolate overflow-hidden bg-white py-12 sm:py-24">
      <div class="absolute -top-80 left-[max(6rem,33%)] -z-10 transform-gpu blur-3xl sm:left-1/2 md:top-20 lg:ml-20 xl:top-3 xl:ml-56" aria-hidden="true">
        <div class="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30" style="clip-path: polygon(63.1% 29.6%, 100% 17.2%, 76.7% 3.1%, 48.4% 0.1%, 44.6% 4.8%, 54.5% 25.4%, 59.8% 49.1%, 55.3% 57.9%, 44.5% 57.3%, 27.8% 48%, 35.1% 81.6%, 0% 97.8%, 39.3% 100%, 35.3% 81.5%, 97.2% 52.8%, 63.1% 29.6%)"></div>
      </div>
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl lg:mx-0">
          <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">How it works</h1>
          <p class="mt-6 text-xl leading-8 text-gray-700">Empowering Your Success: Connect Talent, Earn Steadily</p>
        </div>
        <div class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:mt-10 lg:max-w-none lg:grid-cols-12">
          <div class="relative lg:order-last lg:col-span-5">
            <svg class="absolute -top-[40rem] left-1 -z-10 h-[64rem] w-[175.5rem] -translate-x-1/2 stroke-gray-900/10 [mask-image:radial-gradient(64rem_64rem_at_111.5rem_0%,white,transparent)]" aria-hidden="true">
              <defs>
                <pattern id="e87443c8-56e4-4c20-9111-55b82fa704e3" width="200" height="200" patternUnits="userSpaceOnUse">
                  <path d="M0.5 0V200M200 0.5L0 0.499983" />
                </pattern>
              </defs>
              <rect width="100%" height="100%" stroke-width="0" fill="url(#e87443c8-56e4-4c20-9111-55b82fa704e3)" />
            </svg>
            <figure class="border-l border-indigo-600 pl-8">
              <blockquote class="text-xl font-semibold leading-8 tracking-tight text-gray-900">
                <p>“This partner program has been a great source of side income for me. Matching talent with clients is straightforward, and the commissions, up to 15% per contract, are a major financial boost. With its flexible approach and valuable training, it's an excellent opportunity for extra earnings.”</p>
              </blockquote>
              <figcaption class="mt-8 flex gap-x-4">
                <img src="@/assets/avatars/jala.jpg" alt="" class="mt-1 h-10 w-10 flex-none rounded-full bg-gray-50">
                <div class="text-sm leading-6">
                  <div class="font-semibold text-gray-900">Jasmin S.</div>
                  <div class="text-gray-600">@djala</div>
                </div>
              </figcaption>
            </figure>
          </div>
          <div class="max-w-xl text-base leading-7 text-gray-700 lg:col-span-7">
            <p>
              Our program equips you with a portfolio of talented software developers, complete with comprehensive backgrounds, CVs, technical expertise, and language proficiencies. Your role involves finding suitable clients for these professionals. Once a client approves and hires a talent from our pool, you are entitled to a monthly commission of up to 15% on the talent's contract value.
            </p>
            <p class="mt-4">
              For instance, if a talent is contracted at $40/hour, amounting to $6,720 monthly, your potential earnings could be up to $1,000 per month for each talent-client pairing you facilitate.
            </p>
            <p class="mt-4">
              For the initial two talents you successfully connect, a guaranteed 15% commission is ensured. Subsequently, the commission rate will vary between 10% to 15%, depending on the specifics of each contract.
            </p>
            <p class="mt-4">
              Your payments are directly linked to the talent's remuneration schedule. As soon as the talent receives their payment, your commission is processed immediately. Additionally, we offer flexible payment transfer options to suit your convenience.
            </p>

            <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">No client? No problem.</h2>
            <p class="mt-6">Stay with Confidence: No Client, No Pressure. Remain in our Partner Program Indefinitely. Plus, Benefit from Expert Training on Client Engagement and Talent Matching.</p>
          </div>
        </div>
      </div>
    </div>

    <!-- SECTION -->



    <!-- SECTION -->

    <div class="bg-gray-900 py-24 sm:py-32">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl lg:max-w-none">
          <div class="text-center">
            <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl">Trusted by partners worldwide</h2>
            <p class="mt-4 text-lg leading-7 text-gray-300">Boost Your Income with Our Flexible and Rewarding Partner Program</p>
          </div>
          <dl class="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
            <div class="flex flex-col bg-white/5 p-8">
              <dt class="text-sm font-semibold leading-6 text-gray-300">Partners on the platform</dt>
              <dd class="order-first text-3xl font-semibold tracking-tight text-white">3+</dd>
            </div>
            <div class="flex flex-col bg-white/5 p-8">
              <dt class="text-sm font-semibold leading-6 text-gray-300">Partners fee</dt>
              <dd class="order-first text-3xl font-semibold tracking-tight text-white">up to 15%</dd>
            </div>
            <div class="flex flex-col bg-white/5 p-8">
              <dt class="text-sm font-semibold leading-6 text-gray-300">Payment guarantee</dt>
              <dd class="order-first text-3xl font-semibold tracking-tight text-white">99.9%</dd>
            </div>
            <div class="flex flex-col bg-white/5 p-8">
              <dt class="text-sm font-semibold leading-6 text-gray-300">Paid out to partners</dt>
              <dd class="order-first text-3xl font-semibold tracking-tight text-white">$25,000 +</dd>
            </div>
          </dl>
        </div>
      </div>
    </div>


    <!-- SECTION -->



    <!-- SECTION -->

    <div class="bg-white py-12 sm:py-12">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl lg:mx-0">
          <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our talent</h2>
          <p class="mt-5 text-lg leading-7 text-gray-600">Our talent comprises a team of passionate individuals, dedicated to delivering the highest quality results for our clients.</p>
        </div>
        <ul role="list" class="mx-auto mt-20 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 text-center sm:grid-cols-3 md:grid-cols-4 lg:mx-0 lg:max-w-none lg:grid-cols-5 xl:grid-cols-6">
          <li>
            <img class="mx-auto h-24 w-24 rounded-full" src="@/assets/employees/edin.jpg"  alt="">
          </li>
          <li>
            <img class="mx-auto h-24 w-24 rounded-full" src="@/assets/employees/vedad.jpg"  alt="">
          </li>
          <li>
            <img class="mx-auto h-24 w-24 rounded-full" src="@/assets/employees/senad.jpg"  alt="">
          </li>
          <li>
            <img class="mx-auto h-24 w-24 rounded-full" src="@/assets/employees/sadin.jpg"  alt="">
          </li>
          <li>
            <img class="mx-auto h-24 w-24 rounded-full" src="@/assets/employees/nahid.jpg"  alt="">
          </li>
          <li>
            <img class="mx-auto h-24 w-24 rounded-full" src="@/assets/employees/ivica.jpg"  alt="">
          </li>
          <li>
            <img class="mx-auto h-24 w-24 rounded-full" src="@/assets/employees/hanad.jpg"  alt="">
          </li>
          <li>
            <img class="mx-auto h-24 w-24 rounded-full" src="@/assets/employees/emir.jpg"  alt="">
          </li>
          <li>
            <img class="mx-auto h-24 w-24 rounded-full" src="@/assets/employees/amina.jpg"  alt="">
          </li>
          <li>
            <img class="mx-auto h-24 w-24 rounded-full" src="@/assets/employees/amar.jpg"  alt="">
          </li>
          <li>
            <img class="mx-auto h-24 w-24 rounded-full" src="@/assets/employees/admir.jpg"  alt="">
          </li>
        </ul>
      </div>
    </div>


    <!-- SECTION -->




  </div>
</template>

<script>

export default {
  name: 'Partner-program',
  components: {

  },
  data () {
    return {

    }
  },

  methods: {

  },

  mounted () {
    
  }
}
</script>

<style lang="scss" scoped>

</style>