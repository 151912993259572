<template>
  <div id='contact' class="contact">
    <div class="relative bg-white pt-8" style="background: #F8FAFC;">
      <div class="lg:absolute lg:inset-0 lg:left-1/2">
        <img class="h-64 w-full bg-gray-50 object-cover sm:h-80 lg:absolute lg:h-full" src="@/assets/team/contact.jpg" alt="">
      </div>
      <div class="pb-24 pt-16 sm:pb-32 sm:pt-24 lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:pt-32">
        <div class="px-6 lg:px-8">
          <div class="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
            <h2 class="text-3xl font-bold tracking-tight text-gray-900">Connect with Our Experts</h2>
            <p class="mt-2 text-normal leading-2 text-gray-600">We're Here to Provide Exceptional Support and Solutions <br/> for Your Tech Needs</p>
            <div action="#" method="POST" class="mt-16">
              <div class="grid grid-cols-1 gap-y-6">
                <div>
                  <div class="flex justify-between text-sm leading-6">
                    <label for="phone" class="block font-semibold text-gray-900">Name</label>
                    <p class="text-gray-400">Optional</p>
                  </div>
                  <div class="mt-2.5">
                    <input type="text" name="name" v-model='contact.name' autocomplete="given-name" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                  </div>
                </div>
                <div class="sm:col-span-2">
                  <div class="flex justify-between text-sm leading-6">
                    <label for="phone" class="block font-semibold text-gray-900">Email</label>
                    <p class="text-gray-400">Required</p>
                  </div>
                  <div class="mt-2.5">
                    <input v-model='contact.email' name="email" type="email" autocomplete="email" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                  </div>
                </div>

                <div class="sm:col-span-2">
                  <div class="flex justify-between text-sm leading-6">
                    <label for="phone" class="block font-semibold text-gray-900">Phone</label>
                    <p class="text-gray-400">Optional</p>
                  </div>
                  <div class="mt-2.5">
                    <input type="tel" v-model='contact.phone' autocomplete="tel" aria-describedby="phone-description" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                  </div>
                </div>

                <div class="sm:col-span-2">
                  <div class="flex justify-between text-sm leading-6">
                    <label for="phone" class="block font-semibold text-gray-900">Company</label>
                    <p class="text-gray-400">Optional</p>
                  </div>
                  <div class="mt-2.5">
                    <input type="text" v-model='contact.company' autocomplete="organization" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                  </div>
                </div>

                

                <div class="sm:col-span-2">
                  <div class="flex justify-between text-sm leading-6">
                    <label for="phone" class="block font-semibold text-gray-900">Company</label>
                    <p class="text-gray-400">Optional</p>
                  </div>
                  <div class="mt-2.5">
                    <div>
                      <select v-model='contact.reason' class="mt-2 block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        <option>Get in touch with us</option>
                        <option>Hire engineer</option>
                        <option>Join our team</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="sm:col-span-2">
                  <div class="flex justify-between text-sm leading-6">
                    <label for="message" class="block text-sm font-semibold leading-6 text-gray-900">How can we help you?</label>
                    <p id="message-description" class="text-gray-400">Max 500 characters</p>
                  </div>
                  <div class="mt-2.5">
                    <textarea v-model='contact.message' rows="4" aria-describedby="message-description" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
                  </div>
                </div>

              </div>
              <div class="mt-10 flex justify-end border-t border-gray-900/10 pt-8">
                <button @click="addMessage()" type="submit" class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Send message</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import api from '@/api/api'

export default {
  name: 'Contact-section',
  components: {

  },
  data () {
    return {
      contact: {
        name: '',
        email: '',
        phone: '',
        company: '',
        reason: 'Get in touch with us',
        message: '',
      }
    }
  },

  //created at kasni 2 sata

  methods: {
    async addMessage () {

      console.log(this.validateEmail(this.contact.email))

      if (this.contact.email == '') {
        this.$toast.error('Please add your email.')
        return
      }

      if (this.validateEmail()) {
        this.$toast.error('Please enter a valid email.')
        return
      }

      if (this.contact.message == '') {
        this.$toast.error('Please add your message.')
        return
      }

      let response = await api.addMessage(this.contact)
      console.log('MESSAGE', response)
      if (response.request.status < 300) {
        this.$toast.success('Thanks for contacting us! We will get in touch with you shortly.')
        this.resetMessage()
      } else {
        console.log('error')
      }
    },

    validateEmail() {
      const emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
      return !emailPattern.test(this.contact.email)
    },

    resetMessage () {
      this.contact.name = ''
      this.contact.email = ''
      this.contact.phone = ''
      this.contact.company = ''
      this.contact.reason = 'Get in touch with us'
      this.contact.message = ''
    },

    selectHire () {
      this.contact.reason = 'Hire engineer'
    },

    selectGetinTouch () {
      this.contact.reason = 'Get in touch with us'
    },

    selectJoin () {
      this.contact.reason = 'Join our team'
    }

  },

  mounted () {
    this.validateEmail()
  }
}
</script>

<style lang="scss" scoped>

</style>