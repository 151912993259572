<template>
  <div class="employee">
    <main class="flex-auto">
      <div class="sm:px-8 mt-16 sm:mt-32">
        <div class="mx-auto w-full max-w-7xl lg:px-8">
          <div class="relative px-4 sm:px-8 lg:px-12">
            <div class="mx-auto max-w-2xl lg:max-w-5xl">
              <div class="grid grid-cols-1 gap-y-16 lg:grid-cols-2 lg:grid-rows-[auto_1fr] lg:gap-y-12">
                <div class="lg:pl-20">
                  <div class="max-w-xs px-2.5 lg:max-w-none">
                    <img alt="" loading="lazy" width="800" height="800" decoding="async" data-nimg="1" class="aspect-square rotate-3 rounded-2xl bg-zinc-100 object-cover dark:bg-zinc-800" sizes="(min-width: 1024px) 32rem, 20rem" src="@/assets/employees/emir.jpg" style="color: transparent;">
                  </div>
                </div>
                <div class="lg:order-first lg:row-span-2">
                  <h1 class="text-4xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
                    Emir Avdispahic, <br/> Senior Full stack engineer.
                  </h1>

                  <div class="exp">
                    7+ years of professional experience
                  </div>

                  <div class="ver text-sm text-green-600">
                    <div class="mt-1 ">
                      <svg  style='display: inline;margin-top: -3px;' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                      </svg>
                      Verified in C#
                    </div>
                    <div class="mt-0.5">
                      <svg  style='display: inline;margin-top: -3px;' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                      </svg>
                      Verified in Javascript (Vue, Angular, Node)
                    </div>
                    <div class="mt-0.5">
                      <svg style='display: inline;margin-top: -3px;' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
                      </svg>
                      Team Lead
                    </div>
                  </div>

                  <div class="mt-6 mb-8 space-y-7 text-base text-zinc-600 dark:text-zinc-400">
                    <p>
                      Emir Avdispahic, with a master's degree in Electrical Engineering under his belt, has carved a niche for himself as an adept Full Stack Developer in the tech sphere. Stemming from a solid engineering background, Emir brings an analytical perspective, consistently devising innovative solutions for intricate challenges. In backend development, his expertise lies in Node.js and .NET, where he builds powerful and scalable server-side frameworks. <br/><br/>
                      On the frontend, he excels with Angular, creating intuitive and engaging user interfaces. Always driven by a commitment to excellence, Emir goes above and beyond, often surpassing client anticipations. Beyond his individual contributions, he's also celebrated as an exemplary team player, working seamlessly with peers to achieve shared objectives. Emir's unwavering professionalism is evident in all his endeavors, marking him as a go-to expert for comprehensive software projects.
                    </p>

                    <div>
                      <div class="relative z-10 order-first mb-3 flex items-center text-normal text-zinc-600 dark:text-zinc-500">
                        <span class="font-semibold">Education</span>
                      </div>
                      <div>
                        <span class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">
                          University of Sarajevo, Master of Engineering - MEng, Electrical and Electronics Engineering
                        </span>
                        <br/>
                        <a href="https://www.etf.unsa.ba/" target="_blank" class="text-blue-400">https://www.etf.unsa.ba/</a>
                      </div>
                    </div>

                    <div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">WebPrax - Bonn(Germany) - Full-Stack Engineer</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                        Emir Avdispahic, a Full Stack Software Developer at WebPrax, has been instrumental in revolutionizing online medical consultations through the creation of bespoke solutions for virtual sessions with doctors. Spearheading the backend development with .NET and C#, Emir has ensured a solid and reliable foundation for the platform, guaranteeing secure, efficient, and seamless interactions. <br/><br/>
                        His proficiency doesn't stop there; on the frontend, he adeptly utilizes both Angular and Vue.js, crafting user interfaces that are not only intuitive but also deliver a stellar user experience. This dual expertise ensures that every facet of the platform, from the underlying systems to the visible interfaces, meets the highest standards of quality and functionality. <br/><br/>
                        Emir's role at WebPrax exemplifies the blend of technical acumen and innovative thinking, making him a cornerstone in the company's mission to bridge healthcare and technology.
                      </div>
                    </div>

                    <div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">FORSTA (Deloitte) - Sarajevo(BiH) - Backend Tech Lead</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">

                        Emir Avdispahic, a seasoned software developer at FORSTA, has carved out a distinguished role as a Senior Backend .NET Developer. With a deep-seated expertise in .NET frameworks, Emir has been pivotal in shaping and scaling the backbone of FORSTA's software applications. His profound understanding of system architecture and design, combined with his proficiency in coding, ensures the delivery of robust and seamless solutions. <br/><br/>

                        Beyond his technical acumen, Emir stands out for his collaborative spirit. As a dedicated team player, he not only contributes to the project's success but also elevates the collective knowledge of his peers. <br/><br/>
                        Emir's commitment to excellence and his passion for technology make him an invaluable asset to the FORSTA team and the broader development community.
                      </div>
                    </div>

                    <div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">Porsche (EU Region) Full-Stack Engineer</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                        Emir Avdispahic serves as the Application Manager for Porsche, where his technological prowess and leadership skills are showcased in the realm of application development and management. Harnessing the capabilities of Node.js for backend operations, Emir ensures that the applications under his stewardship are both efficient and scalable. <br/><br/>
                        Furthermore, his expertise in JavaScript and Angular plays a pivotal role in crafting intuitive and dynamic front-end interfaces, aligning with Porsche's premium brand standards. Emir's role is not just limited to technical dimensions; as a manager, he provides strategic direction, ensuring that the applications not only serve their immediate purpose but also align with Porsche's long-term digital vision. <br/><br/>
                        His commitment to excellence, combined with a deep understanding of the automotive luxury market, makes Emir Avdispahic an indispensable asset to Porsche's tech-driven initiatives.
                      </div>
                    </div>

                    <div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">LimBau Rechnungs Buch (Germany) - Full Stack Software Engineer</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                        Emir Avdispahic, serving as a Software Developer at LimBau Rechnungs Buch in Germany, plays a crucial role in refining the financial tech landscape through his contributions to their flagship application. This application, specifically designed for meticulous tracking of invoices and adept management of payments, is a testament to Emir's dedication to creating tools that optimize financial operations. <br/><br/>By leveraging the capabilities of Node.js, he ensures that the backend logic of the application runs smoothly, securely, and efficiently. In addition, his expertise with EJS (Embedded JavaScript) templates facilitates the generation of dynamic web content, making user interactions intuitive and seamless. <br/><br/>In a realm where precision and reliability are paramount, Emir's technical acumen combined with his understanding of financial workflows positions him as a vital contributor to LimBau Rechnungs Buch's success in the FinTech domain.
                      </div>
                    </div>

                    <div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">DeBa Code (Germany) - Full Stack Software Engineer</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                        Emir Avdispahic, a Software Developer at DeBa Code in Germany, has been instrumental in developing cutting-edge software solutions tailored for Sander Automation. Specializing in creating windows applications, Emir's expertise lies in devising software that prepares and optimizes inputs for servo presses. <br/><br/>
                        His keen understanding of diverse press needs ensures that the software's calculation function is versatile enough to accommodate varying requirements while delivering optimal outputs. By leveraging the robust capabilities of Node.js, Emir guarantees a reliable backend, ensuring the software runs seamlessly. <br/><br/>
                        Furthermore, his proficiency in JavaScript empowers him to craft dynamic functionalities that enhance user interactions and experience. In a field that demands precision and efficiency, Emir Avdispahic stands out, providing Sander Automation with tools that elevate their operational prowess.
                      </div>
                    </div>

                  </div>
                </div>
                <div class="lg:pl-20">
                  <button v-if='1==0' @click='contactActive=true' type="button" class="content-center rounded-md bg-indigo-600 px-10 py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 w-full">
                    Hire Emir
                  </button>

                  <div role="list">
                    <div class="mt-8 border-t border-zinc-100 pt-8 dark:border-zinc-700/40">
                      <div style="height: 40px;">
                        <span class="tag border-indigo">.NET</span>
                        <span class="tag border-indigo">C#</span>
                        <span class="tag border-indigo">Node.js</span>
                        <span class="tag border-indigo">JavaScript</span>
                      </div>
                      <div style="height: 40px;">
                        <span class="tag border-indigo">Angular</span>
                        <span class="tag border-indigo">Vue.js</span>
                        <span class="tag border-indigo">MySQL</span>
                        <span class="tag border-indigo">CSS</span>
                      </div>
                      <div style="height: 40px;">
                        <span class="tag border-indigo">Bootstrap</span>
                        <span class="tag border-indigo">HTML</span>
                        <span class="tag border-indigo">API</span>
                        <span class="tag border-indigo">REST</span>
                      </div>
                      <div style="height: 40px;">
                        <span class="tag border-indigo">Apache</span>
                      </div>
                    </div>
                  </div>

                  <div role="list" class="mt-4">
                    <span class="font-semibold">Languages:</span>
                    <div class="mt-4 border-t border-zinc-100 pt-4 dark:border-zinc-700/40">
                      <div style="height: 40px;">
                        <span class="tag" style="border-color: green; color: green">English</span>
                        <span class="tag" style="border-color: green; color: green">German A2</span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <div v-if='contactActive' class="contact-section">
      <Contact />
    </div>

  </div>
</template>

<script>
import Contact from "@/views/employee/Contact"

export default {
  name: 'Employee-page-emir',
  components: {
    Contact
  },
  data () {
    return {
      contactActive: false
    }
  },

  methods: {

  },

  mounted () {
    
  }
}
</script>

<style lang="scss" scoped>

.tag {
  margin-right: 15px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #2764EA;
  border-radius: 15px;
  color: #2764EA;
}

.contact-section {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.exp {
  margin-top: 10px;
  font-weight: 500;
  color: #2764EA;
}

</style>