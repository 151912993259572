<template>
  <div class="employee">
    <main class="flex-auto">
      <div class="sm:px-8 mt-16 sm:mt-32">
        <div class="mx-auto w-full max-w-7xl lg:px-8">
          <div class="relative px-4 sm:px-8 lg:px-12">
            <div class="mx-auto max-w-2xl lg:max-w-5xl">
              <div class="grid grid-cols-1 gap-y-16 lg:grid-cols-2 lg:grid-rows-[auto_1fr] lg:gap-y-12">
                <div class="lg:pl-20">
                  <div class="max-w-xs px-2.5 lg:max-w-none">
                    <img alt="" loading="lazy" width="800" height="800" decoding="async" data-nimg="1" class="aspect-square rotate-3 rounded-2xl bg-zinc-100 object-cover dark:bg-zinc-800" sizes="(min-width: 1024px) 32rem, 20rem" src="@/assets/employees/nahid.jpg" style="color: transparent;">
                  </div>
                </div>
                <div class="lg:order-first lg:row-span-2">
                  <h1 class="text-4xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
                    Nahid Brica, <br/> Senior Full stack engineer.
                  </h1>

                  <div class="exp">
                    6+ years of professional experience
                  </div>

                  <div class="ver text-sm text-green-600">
                    <div class="mt-1 ">
                      <svg  style='display: inline;margin-top: -3px;' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                      </svg>
                      Verified in PHP (Laravel, Lumen, CodeIgniter)
                    </div>
                    <div class="mt-0.5">
                      <svg  style='display: inline;margin-top: -3px;' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                      </svg>
                      Verified in Javascript (Vue, React)
                    </div>
                    <div class="mt-0.5">
                      <svg style='display: inline;margin-top: -3px;' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
                      </svg>
                      Team Lead
                    </div>
                  </div>

                  <div class="mt-6 mb-8 space-y-7 text-base text-zinc-600 dark:text-zinc-400">
                    <p>
                      Nahid Brica stands as a distinguished full-stack developer, bringing to the table over six years of professional experience in software development. Beginning his coding odyssey in 2013 during university, he transitioned from a budding enthusiast to a full-fledged professional by 2016. <br/><br/>
                      Nahid initiated his career armed with proficiency in JavaScript and PHP. Yet, it was his immersion into the Laravel and Vue frameworks that truly marked a turning point in his professional journey. Owing to his comprehensive expertise spanning front-end, back-end, and database management, Nahid has the capability to shepherd projects from inception to fruition independently. <br/><br/>
                      Outside of his core undertakings, he has dabbled in creating specialized projects on the WordPress platform. Impressively, his professional portfolio boasts collaborations with prestigious clients from the USA and EU, emphasizing his expansive reach in the tech sector.
                    </p>

                    <div>
                      <div class="relative z-10 order-first mb-3 flex items-center text-normal text-zinc-600 dark:text-zinc-500">
                        <span class="font-semibold">Education</span>
                      </div>
                      <div>
                        <span class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">
                          University of Buckingham (SSST), Buckingham England (Sarajevo) - Computer Science(major), Electrical Engineering(minor)
                        </span>
                        <br/>
                        <a href="https://www.buckingham.ac.uk/" target="_blank" class="text-blue-400">https://www.buckingham.ac.uk/</a>
                      </div>
                    </div>

                    <div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">Mommnt - San Francisco(USA) - Front-Stack Engineer</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                        Nahid Brica serves as a Front-End Developer at Mommnt, a pioneering entity in the fintech sector that delivers state-of-the-art lending solutions. With a focus on Vue.js, Nahid plays an instrumental role in sculpting intuitive and efficient user interfaces that stand at the forefront of Mommnt's offerings. <br/><br/>
                        These interfaces serve to streamline the lending process, enabling businesses to furnish their customers with effortless and cost-effective financing options precisely when required. Through his contributions, Nahid aids Mommnt in achieving its vision of revolutionizing the lending landscape, ensuring clients can provide unparalleled financial services at pivotal moments. In a space where user experience is paramount, Nahid Brica's expertise fortifies Mommnt's commitment to providing seamless and modern lending solutions.
                      </div>
                    </div>

                    <div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">Pynn.it - Frankfurt am Main(Germany) - Full-stack Tech Lead</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                        Nahid Brica held the position of a Full-Stack Web Developer at Pynn.it in Frankfurt am Main. During this tenure, he demonstrated a commendable aptitude for problem-solving, both as an autonomous contributor and as an integral part of a collaborative team. Nahid earned a reputation as a dedicated and punctual professional, consistently displaying meticulous attention to detail and exemplary record-keeping proficiencies.<br/><br/>

                        Initially, Nahid was entrusted with front-end development responsibilities, utilizing Vue.js as his primary tool. His primary role encompassed structuring and executing the overall application design, seamlessly integrating inputs from the design team. However, showcasing his versatility, Nahid transitioned to back-end development within six months, harnessing the power of PHP, particularly the Laravel framework. <br/><br/>
                        This shift provided him with a holistic understanding of the project's architecture, fueling his passion and drive. The breadth of his responsibilities at Pynn.it not only underlined his technical prowess but also highlighted his adaptability and keen interest in understanding projects from end to end.
                      </div>
                    </div>

                    <div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">BalkanVibe - Sarajevo(BiH)</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                        Nahid Brica excels in his role as a full-stack developer, demonstrating proficiency in both Vue.js and Laravel-PHP frameworks. His approach to software development is deeply rooted in continually reinforcing industry best practices, ensuring that all stages of development meet quality and regulatory standards.<br/><br/>

                        In his dynamic role, Nahid has proven to be exceptionally adept at multitasking across various projects. He seamlessly integrates his efforts with those of internal and external teams, fostering a collaborative environment that has been instrumental in driving project success. This ability to work synergistically within diverse teams underscores his role as a unifying force in project execution.<br/><br/>

                        Moreover, Nahid possesses a keen diagnostic acumen, particularly evident in his troubleshooting prowess. He consistently provides effective remediation strategies for web applications, addressing system discrepancies, and ensuring optimal functionality. His forward-thinking approach in resolving technical issues safeguards user experience and maintains the integrity of the digital platforms he works on.<br/><br/>

                        Throughout his tenure, Nahid Brica has epitomized the essence of a balanced professional, merging technical skill with interpersonal collaboration and operational excellence.
                      </div>
                    </div>

                  </div>
                </div>
                <div class="lg:pl-20">
                  <button v-if='1==0' @click='contactActive=true' type="button" class="content-center rounded-md bg-indigo-600 px-10 py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 w-full">
                    Hire Nahid
                  </button>

                  <div role="list">
                    <div class="mt-8 border-t border-zinc-100 pt-8 dark:border-zinc-700/40">
                      <div style="height: 40px;">
                        <span class="tag border-indigo">PHP</span>
                        <span class="tag border-indigo">Laravel</span>
                        <span class="tag border-indigo">Lumen</span>
                        <span class="tag border-indigo">JavaScript</span>
                      </div>
                      <div style="height: 40px;">
                        <span class="tag border-indigo">Vue.js</span>
                        <span class="tag border-indigo">Vuetify.js</span>
                        <span class="tag border-indigo">Quasar.js</span>
                      </div>
                      <div style="height: 40px;">
                        <span class="tag border-indigo">Wordpress</span>
                        <span class="tag border-indigo">MySQL</span>
                        <span class="tag border-indigo">Tailwind</span>
                        <span class="tag border-indigo">CSS</span>

                      </div>
                      <div style="height: 40px;">
                        <span class="tag border-indigo">Bootstrap</span>
                        <span class="tag border-indigo">CSS</span>
                        <span class="tag border-indigo">HTML</span>
                      </div>
                      <div style="height: 40px;">
                      </div>
                    </div>
                  </div>

                  <div role="list" class="mt-4">
                    <span class="font-semibold">Languages:</span>
                    <div class="mt-4 border-t border-zinc-100 pt-4 dark:border-zinc-700/40">
                      <div style="height: 40px;">
                        <span class="tag" style="border-color: green; color: green">English</span>
                        <span class="tag" style="border-color: green; color: green">German A2</span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <div v-if='contactActive' class="contact-section">
      <Contact />
    </div>

  </div>
</template>

<script>
import Contact from "@/views/employee/Contact"

export default {
  name: 'Employee-page-nahid',
  components: {
    Contact
  },
  data () {
    return {
      contactActive: false
    }
  },

  methods: {

  },

  mounted () {
    
  }
}
</script>

<style lang="scss" scoped>

.tag {
  margin-right: 15px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #2764EA;
  border-radius: 15px;
  color: #2764EA;
}

.contact-section {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.exp {
  margin-top: 10px;
  font-weight: 500;
  color: #2764EA;
}

</style>