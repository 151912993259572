<template>
  <div class="dashboard-section">

   <div class="flex flex-1 flex-col md:pl-64">
     <main class="flex-1">
       <div class="py-6">
         <div v-if='user' class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
           <h1 class="text-2xl font-semibold text-gray-900" style="padding-bottom: 15px;border-bottom: 1px solid #eee;">
            Dashboard
            <span v-if='user && parseInt(user.debt)>0' class="debt">Debt: {{ (user.debt).toLocaleString('en-US') }} BAM</span>
          </h1>
         </div>



         <div class="px-4 sm:px-6 lg:px-8 mt-8">
           <div class="sm:flex-auto">
             <p class="mt-2 text-blue-800 font-semibold">Stats:</p>
           </div>
           <dl class="mt-3 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-2 md:divide-y-0 md:divide-x">
             
             <div class="px-4 py-5 sm:p-6">
               <dt class="text-base text-gray-900 font-semibold" style="color: #2a9d8f">Paid</dt>
               <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
                 <div class="flex items-baseline text-2xl font-semibold text-gray-600">
                   {{paid.toLocaleString('en-US')}} <span class="text-sm pl-1">BAM</span>
                 </div>
               </dd>
             </div>

             <div class="px-4 py-5 sm:p-6">
               <dt class="text-base font-semibold text-gray-900" style="color: #f4a261">Outstanding</dt>
               <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
                 <div class="flex items-baseline text-2xl font-semibold text-gray-600">
                   {{outstanding.toLocaleString('en-US')}} <span class="text-sm pl-1">BAM</span>
                 </div>
               </dd>
             </div>

           </dl>
         </div>

 

         <!-- table -->
         <div class="px-4 sm:px-6 lg:px-8 mt-5">
           <div class="sm:flex sm:items-center">
             <div class="sm:flex-auto">
               <p class="mt-2 text-blue-800 font-semibold">Payments waiting your approval:</p>
             </div>
           </div>
           <div class="mt-3 flex flex-col">
             <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
               <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                 <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                   <table class="min-w-full divide-y divide-gray-300">
                     <thead class="bg-gray-50">
                       <tr>
                         <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Days worked</th>
                         <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Salary for Period</th>
                         <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Payment Date</th>
                         <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Amount</th>
                         <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                         <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                           <span class="sr-only">Edit</span>
                         </th>
                       </tr>
                     </thead>
                     <tbody v-if='payments' class="bg-white">
                       <!-- Odd row -->
                       <tr v-for="(payment, index) in otherPayments" :key='index' :class="{'bg-gray-50': index%2}">
                         <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{{payment.days_worked}} / {{payment.total_days}}</td>
                         <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                           {{payment.pay_period}}
                         </td>
                         <td class="whitespace-nowrap px-3 py-4 text-sm text-green-500">
                           {{payment.expected_payment_date}}
                         </td>
                         <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                           {{ payment.expensess.toLocaleString('en-US') }} BAM
                         </td>
                         <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-700" style="font-weight: 600;">
                          <span v-if="payment.status == 'created'">
                            {{payment.status}}
                          </span>
                          <span v-if="payment.status == 'confirmed'" style="color: #F49D1A">
                            {{payment.status}}
                          </span>
                          <span v-if="payment.status == 'paid'" style="color: #86C8BC">
                           {{payment.status}}
                          </span>
                         </td>
                         <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <div v-if="payment.status == 'created'" @click="changePaymentStatus(payment, 'confirmed')" class="text-indigo-600 hover:text-indigo-900">
                            <button type="button" class="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                              Confirm
                            </button>
                          </div>
                         </td>
                       </tr>

                       <!-- More people... -->
                     </tbody>
                   </table>
                 </div>
               </div>
             </div>
           </div>
         </div>
         <!-- table -->


         <!-- table -->
         <div class="px-4 sm:px-6 lg:px-8 mt-5">
           <div class="sm:flex sm:items-center">
             <div class="sm:flex-auto">
               <p class="mt-2 text-green-800 font-semibold">Paid Payments:</p>
             </div>
           </div>
           <div class="mt-3 flex flex-col">
             <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
               <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                 <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                   <table class="min-w-full divide-y divide-gray-300">
                     <thead class="bg-gray-50">
                       <tr>
                         <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Days worked</th>
                         <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Salary for Period</th>
                         <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Payment Date</th>
                         <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Amount</th>
                         <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                         <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                           <span class="sr-only">Edit</span>
                         </th>
                       </tr>
                     </thead>
                     <tbody v-if='payments' class="bg-white">
                       <!-- Odd row -->
                       <tr v-for="(payment, index) in paidPayments" :key='index' :class="{'bg-gray-50': index%2}">
                         <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{{payment.days_worked}} / {{payment.total_days}}</td>
                         <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                           {{payment.pay_period}}
                         </td>
                         <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                           {{payment.expected_payment_date}}
                         </td>
                         <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                           {{ payment.expensess.toLocaleString('en-US') }} BAM
                         </td>
                         <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-700" style="font-weight: 600;">
                          <span v-if="payment.status == 'created'">
                            {{payment.status}}
                          </span>
                          <span v-if="payment.status == 'confirmed'" style="color: #F49D1A">
                            {{payment.status}}
                          </span>
                          <span v-if="payment.status == 'paid'" class="text-green-500" style="text-transform: capitalize">
                           {{payment.status}}
                          </span>
                         </td>
                         <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <div v-if="payment.status == 'created'" @click="changePaymentStatus(payment, 'confirmed')" class="text-indigo-600 hover:text-indigo-900">
                            <button type="button" class="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                              Confirm
                            </button>
                          </div>
                         </td>
                       </tr>

                       <!-- More people... -->
                     </tbody>
                   </table>
                 </div>
               </div>
             </div>
           </div>
         </div>
         <!-- table -->


       </div>
     </main>
   </div>

  </div>
</template>

<script>
import api from '@/api/api'

/* statuses
  created
  confirmed
  paid
*/

export default {
  name: 'Dashboard-view',
  components: {

  },
  data () {
    return {
      user: null,
      payments: null,
      paidPayments: [],
      otherPayments: [],
      paid: 0,
      outstanding: 0
    }
  },

  methods: {

    async userPayments () {
      let response = await api.userPayments()
      console.log('response', response)
      if (response.request.status < 300) {

        response.data.forEach(item => {
          if (item.status == 'paid') {
            this.paidPayments.push(item)
            this.paid += parseFloat(item.expensess)
          } else {
            this.otherPayments.push(item)
            this.outstanding += parseFloat(item.expensess)
          }
        })

        this.payments = response.data
        console.log('payments', this.payments)
      } else {
        console.log('error')
      }
    },

    async changePaymentStatus (payment, status) {

      let data = {
        id: payment.id,
        status: status
      }

      let response = await api.changePaymentStatus(data)
      console.log('response status', response)
      if (response.request.status < 300) {
        payment.status = status
        this.$toast.success('Payment ' + status)
        console.log('response status', response)
      } else {
        console.log('error')
      }
    }

  },

  mounted () {
    this.userPayments()
    this.user = JSON.parse(localStorage.getItem('user'))
    console.log('user', this.user)
  }
}
</script>

<style lang="scss" scoped>

  .debt {
    float: right;
    font-size: 18px;
    color: #f57317;
  }

</style>