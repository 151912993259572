import axios from 'axios'

// token 
const customToken = 'Bearer ' + localStorage.getItem('_token')

// URL of backend server
//const baseUrl = 'http://localhost/' // dev
const baseUrl = 'https://api.zimablue.ai/'

// axios options (TO DO move to config)
let axiosOptions

if (customToken) {
    axiosOptions = {
      baseURL: baseUrl,
      headers: { 
          Authorization: customToken
      }
    }
} else {
    axiosOptions = {
      baseURL: baseUrl,
    }
}

const instance = axios.create(axiosOptions);

export default {

  login (data) {
    return instance
    .post('/api/login', data)
    .then( (res) => {
      return res
    })
    .catch((error) => {
      return error
    });
  },

  register (data) {
    return instance
    .post('/api/register', data)
    .then( (res) => {
      return res
    })
    .catch((error) => {
      return error
    });
  },

  logout () {
    return instance
    .post('/api/logout')
    .then( (res) => {
      return res
    })
    .catch((error) => {
      return error
    });
  },

  me () {
    return instance
    .get('/api/me')
    .then( (res) => {
      return res
    })
    .catch((error) => {
      return error
    });
  },

  // users

  getUsers () {
    return instance
    .get('/api/users')
    .then( (res) => {
      return res
    })
    .catch((error) => {
      return error
    });
  },

  // payments

  createPayment (data) {
    return instance
    .post('/api/payment', data)
    .then( (res) => {
      return res
    })
    .catch((error) => {
      return error
    });
  },

  getPayments () {
    return instance
    .get('/api/payments')
    .then( (res) => {
      return res
    })
    .catch((error) => {
      return error
    });
  },

  userPayments () {
    return instance
    .get('/api/user-payments')
    .then( (res) => {
      return res
    })
    .catch((error) => {
      return error
    });
  },

  getPaymentForUser () {
    return instance
    .get('/api/payment-for-user')
    .then( (res) => {
      return res
    })
    .catch((error) => {
      return error
    });
  },

  changePaymentStatus (data) {
    return instance
    .post('/api/payment/change-status', data)
    .then( (res) => {
      return res
    })
    .catch((error) => {
      return error
    });
  },

  addMessage (data) {
    return instance
    .post('/api/message', data)
    .then( (res) => {
      return res
    })
    .catch((error) => {
      return error
    });
  },

  // CAR DETAILS
  addExpese (data) {
    return instance
    .post('/api/expense', data)
    .then( (res) => {
      return res
    })
    .catch((error) => {
      return error
    });
  },

  getExpese () {
    return instance
    .get('/api/expenses')
    .then( (res) => {
      return res
    })
    .catch((error) => {
      return error
    });
  },

  getExpensesThisMonth () {
    return instance
    .get('/api/expenses/month')
    .then( (res) => {
      return res
    })
    .catch((error) => {
      return error
    });
  },

  deleteExpense (id) {
    console.log('id', id)
    return instance
    .get('/api/expense/delete/'+ id)
    .then( (res) => {
      return res
    })
    .catch((error) => {
      return error
    });
  }

}