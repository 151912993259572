<template>
  <div class="employee">
    <main class="flex-auto">
      <div class="sm:px-8 mt-16 sm:mt-32">
        <div class="mx-auto w-full max-w-7xl lg:px-8">
          <div class="relative px-4 sm:px-8 lg:px-12">
            <div class="mx-auto max-w-2xl lg:max-w-5xl">
              <div class="grid grid-cols-1 gap-y-4 lg:grid-cols-2 lg:grid-rows-[auto_1fr] lg:gap-y-12">
                <div class="lg:pl-20">
                  <div class="max-w-xs px-2.5 lg:max-w-none">
                    <img alt="" loading="lazy" width="800" height="800" decoding="async" data-nimg="1" class="aspect-square rotate-3 rounded-2xl bg-zinc-100 object-cover dark:bg-zinc-800" sizes="(min-width: 1024px) 32rem, 20rem" src="@/assets/employees/vedad.jpg" style="color: transparent;">
                  </div>
                </div>
                <div class="lg:order-first lg:row-span-2">
                  <h1 class="text-4xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
                    Vedad S., <br/> Senior Front-end developer and Designer.
                  </h1>

                  <div class="exp">
                    5+ years of professional experience
                  </div>

                  <div class="ver text-sm text-green-600">
                    <div class="mt-0.5">
                      <svg  style='display: inline;margin-top: -3px;' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                      </svg>
                      Verified in Javascript (Vue, React, Node)
                    </div>
                    <div class="mt-0.5">
                      <svg  style='display: inline;margin-top: -3px;' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                      </svg>
                      Verified in Design
                    </div>
                  </div>

                  <div class="mt-6 mb-8 space-y-7 text-base text-zinc-600 dark:text-zinc-400">
                    <p>
                      Vedad Sitarevic is a front end software engineer with more than 5 years of experience. 
                      As a dedicated Front End Software Developer at Zima Blue Software, I specialize in creating innovative and efficient frontend solutions using cutting-edge frameworks like Vue.js and React. My expertise encompasses not only the technical intricacies of these technologies but also the art of designing intuitive and captivating UI/UX interfaces. Passionate about design and the user experience, I consistently deliver digital interfaces that are both aesthetically pleasing and functionally robust. With a keen eye for detail and a fervor for staying abreast of industry trends, I am committed to ensuring that each project I undertake provides an exceptional experience for its end-users.
                    </p>

                    <div>
                      <div class="relative z-10 order-first mb-3 flex items-center text-normal text-zinc-600 dark:text-zinc-500">
                        <span class="font-semibold">Education</span>
                      </div>
                      <div>
                        <span class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">
                          University of Sarajevo, Bachelor of Engineering - Electrical and Electronics Engineering
                        </span>
                        <br/>
                        <a href="https://www.etf.unsa.ba/" target="_blank" class="text-blue-400">https://www.etf.unsa.ba/</a>
                      </div>
                    </div>

                    <div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">Medosync (META) - Dublin(Ireland) - Front-end Engineer / Designer</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">

                        As a Front-End Developer at MedoSync, I've honed my skills in crafting responsive and intuitive user interfaces, predominantly utilizing Vue.js, though I also have proficiency in React. My dual expertise as both a developer and a designer has enabled me to bring a unique blend of technical prowess and aesthetic sensibility to our projects.<br/><br/> 

                        When designing pages for our clientele, I always prioritize user experience, ensuring that each design not only meets but exceeds the user's expectations. Collaborating closely with my team, I've been recognized as a pivotal contributor, always pushing for excellence while fostering a spirit of unity and collaboration. <br/><br/>

                        My adaptability ensures that I'm always equipped to tackle a diverse array of challenges, be it in design or front-end development. My tenure at MedoSync has fortified my belief in the power of seamless integration between design and functionality, and I am proud to have been a part of projects that epitomize this synergy. <br/><br/>
                      </div>
                    </div>

                    <div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">The Hijaz Edu - London(UK) - Front-end Engineer</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                        
                        As a Front-End Developer, I have been deeply involved in crafting an online educational platform that offers a wide range of courses on myriad subjects. My primary toolkit comprises state-of-the-art technologies such as Laravel, Docker, MySQL, Vue.js, and Bootstrap 5. <br/><br/>

                        Leveraging these tools, I've succeeded in creating responsive, user-friendly interfaces that facilitate seamless learning experiences for users. The use of Docker has ensured smooth deployment processes, while MySQL manages our vast databases, maintaining the integrity of our course information and user data. With Vue.js, I've been able to develop dynamic and interactive UI components that resonate with the modern learner's needs. <br/><br/>

                        Bootstrap 5, on the other hand, has been instrumental in ensuring that our platform remains aesthetically pleasing and fully mobile-responsive. My commitment to this project is a testament to my belief in the transformative power of digital education, and I am proud to be contributing to the democratization of knowledge.

                      </div>
                    </div>

                    <div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">Mediji.ba - Sarajevo(BiH) - Full-stack Engineer</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                        
                        Web Design: Leveraged modern HTML/CSS/JS techniques for seamless template adaptation, ensuring a responsive and engaging user interface.<br/><br/>

                        Content Aggregation: Integrated an advanced XML/RSS parsing service, enabling real-time news updates from various regional sources.<br/><br/>

                        Framework Selection: Utilized the robust Laravel PHP Framework, guaranteeing optimal performance, security, and scalability for the platform.<br/><br/>


                        Data Management: Employed a MySQL database system, renowned for its efficiency and reliability, to effectively manage and categorize vast news data.<br/><br/>

                        User Experience: Focused on a user-centric design, ensuring quick access to the latest news from trusted regional outlets, enhancing readability and navigation.<br/><br/>
                      </div>
                    </div>

                    <div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">Fiona - Sarajevo(BiH) - Full-stack Engineer</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                        
                        Online Boutique: Launched a clothing webstore, providing a diverse range of fashion products for discerning customers.<br/><br/>

                        Frontend Framework: Utilized React for dynamic user interfaces, ensuring an immersive and intuitive shopping experience.<br/><br/>

                        Backend Services: Leveraged Node.js, ensuring efficient server-side operations and smooth user interactions.<br/><br/>

                        Database Management: Incorporated MySQL for reliable inventory management, customer data storage, and order tracking.<br/><br/>

                        State Management: Implemented Redux for centralized and predictable state management, enhancing UI consistency and responsiveness.<br/><br/>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="lg:pl-20">
                  <button v-if='1==0' @click='contactActive=true' type="button" class="content-center rounded-md bg-indigo-600 px-10 py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 w-full">
                    Hire Vedad
                  </button>

                  <div role="list">
                    <div class="mt-8 border-t border-zinc-100 pt-8 dark:border-zinc-700/40">
                      <div style="height: 40px;">
                        <span class="tag border-indigo">JavaScript</span>
                        <span class="tag border-indigo">Vue.js</span>
                        <span class="tag border-indigo">React.js</span>
                        <span class="tag border-indigo">CSS</span>
                      </div>
                      <div style="height: 40px;">
                        <span class="tag border-indigo">Bootstrap</span>
                        <span class="tag border-indigo">Restful APIs</span>
                        <span class="tag border-indigo">Vuetify.js</span>
                      </div>
                      <div style="height: 40px;">
                        <span class="tag border-indigo">Quasar.js</span>
                        <span class="tag border-indigo">Unreal</span>
                        <span class="tag border-indigo">HTML</span>
                      </div>
                      <div style="height: 40px;">
                      </div>
                    </div>
                  </div>

                  <div role="list">
                    <span class="font-semibold">Languages:</span>
                    <div class="mt-4 border-t border-zinc-100 pt-4 dark:border-zinc-700/40">
                      <div style="height: 40px;">
                        <span class="tag" style="border-color: green; color: green">English</span>
                      </div>
                      <div style="height: 40px;">
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <div v-if='contactActive' class="contact-section">
      <Contact />
    </div>

  </div>
</template>

<script>
import Contact from "@/views/employee/Contact"

export default {
  name: 'Employee-page-vedad',
  components: {
    Contact
  },
  data () {
    return {
      contactActive: false
    }
  },

  methods: {

  },

  mounted () {
    
  }
}
</script>

<style lang="scss" scoped>

.tag {
  margin-right: 15px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #2764EA;
  border-radius: 15px;
  color: #2764EA;
}

.contact-section {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.exp {
  margin-top: 10px;
  font-weight: 500;
  color: #2764EA;
}

</style>