<template>
  <div class="banner">
    <section id="get-started-today" class="relative overflow-hidden bg-blue-600 py-24">

      <img alt="" src="@/assets/background-call-to-action.jpg" width="2347" height="1244" decoding="async" data-nimg="1" class="absolute top-1/2 left-1/2 max-w-none -translate-x-1/2 -translate-y-1/2" loading="lazy" style="color:transparent">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative">
        <div class="mx-auto max-w-4xl text-center">
          <h2 class="font-display text-4xl tracking-tight text-white sm:text-4xl font-semibold font-lexend">
            {{title}}
          </h2>
          <p class="mt-8 text-lg tracking-tight text-white ">
            {{text}}
          </p>
          <a href="/#contact" @click='selectGetinTouch()' class="group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-white text-slate-900 hover:bg-blue-50 active:bg-blue-200 active:text-slate-600 focus-visible:outline-white mt-10">
            Get in touch with us!
          </a>
        </div>
      </div>

    </section>
  </div>
</template>

<script>

export default {
  name: 'Banner-section',
  props: [
    'title',
    'text'
  ],
  components: {

  },
  data () {
    return {

    }
  },

  methods: {
    selectGetinTouch () {
      this.$parent.$refs.contact.selectGetinTouch()
    }
  },

  mounted () {
    
  }
}
</script>

<style lang="scss" scoped>

  .font-lexend {
    font-family: 'Lexend', sans-serif;  
  }

  .font-inter {
    font-family: 'Inter', sans-serif;
  }

</style>