<template>
  <div class="landing-page-section">
    
    <Header />

    <main>
      
      <Hero />
      <Banner 
        title="Get Your Free Consultation Today!"
        text="Discover unparalleled digital experiences with WebSculpt, your ultimate web development partner!
        Our passionate team of experts combines cutting-edge technology with innovative design, delivering stunning and responsive websites tailored to your business needs. Choose WebSculpt and watch your online presence soar."
      />
      <Projects />

      <Banner 
        title="Empowering Businesses with IT Expertise"
        text="Unlock the full potential of your organization with our comprehensive IT services. From strategic planning to seamless implementation, we drive digital transformation tailored to your unique needs. Experience unparalleled growth and efficiency with our team of dedicated technology experts by your side."
      />
      <People />

      <Testimonial />
      <Contact ref='contact' />
      <Faq />

      <Footer />

    </main>


  </div>
</template>

<script>
  import Header from '@/views/landing/components/Header'
  import Hero from '@/views/landing/components/HeroSection'
  import Projects from '@/views/landing/components/Projects'
  import People from '@/views/landing/components/People'
  import Banner from '@/views/landing/components/Banner'
  import Testimonial from '@/views/landing/components/Testimonial'
  import Contact from '@/views/landing/components/Contact'
  import Faq from '@/views/landing/components/Faq'
  import Footer from '@/views/landing/components/Footer'

export default {
  name: 'Landing-page',
  components: {
    Header,
    Hero,
    Projects,
    People,
    Banner,
    Contact,
    Testimonial,
    Faq,
    Footer
  },
  data () {
    return {

    }
  },

  methods: {

  },

  mounted () {
    
  }
}
</script>

<style lang="scss" scoped>

</style>