<template>
  <div class="employee">
    <main class="flex-auto">
      <div class="sm:px-8 mt-16 sm:mt-32">
        <div class="mx-auto w-full max-w-7xl lg:px-8">
          <div class="relative px-4 sm:px-8 lg:px-12">
            <div class="mx-auto max-w-2xl lg:max-w-5xl">
              <div class="grid grid-cols-1 gap-y-16 lg:grid-cols-2 lg:grid-rows-[auto_1fr] lg:gap-y-12">
                <div class="lg:pl-20">
                  <div class="max-w-xs px-2.5 lg:max-w-none">
                    <img alt="" loading="lazy" width="800" height="800" decoding="async" data-nimg="1" class="aspect-square rotate-3 rounded-2xl bg-zinc-100 object-cover dark:bg-zinc-800" sizes="(min-width: 1024px) 32rem, 20rem" src="@/assets/employees/ivica.jpg" style="color: transparent;">
                  </div>
                </div>
                <div class="lg:order-first lg:row-span-2">
                  <h1 class="text-4xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
                    Ivica Lovrić, <br/> Senior Software Engineer, Data Scientist and Data Engineer.
                  </h1>

                  <div class="exp">
                    6+ years of professional experience
                  </div>

                  <div class="ver text-sm text-green-600">
                    <div class="mt-1 ">
                      <svg  style='display: inline;margin-top: -3px;' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                      </svg>
                      Verified in Python
                    </div>
                    <div class="mt-1 ">
                      <svg  style='display: inline;margin-top: -3px;' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                      </svg>
                      Verified in Data Scientist
                    </div>
                    <div class="mt-1 ">
                      <svg  style='display: inline;margin-top: -3px;' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                      </svg>
                      Verified in Machine Learning
                    </div>
                    <div class="mt-0.5">
                      <svg style='display: inline;margin-top: -3px;' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
                      </svg>
                      Team Lead
                    </div>
                  </div>

                  <div class="mt-6 mb-8 space-y-7 text-base text-zinc-600 dark:text-zinc-400">
                    <p>
                      Detail-oriented software engineer with just a bit over 6 years of professional experience with a
                      diverse skill set in backend development, data engineering and data science. Committed to
                      developing efficient, scalable, and reliable backend systems, as well as leveraging data to extract
                      meaningful insights. Able to work in collaborative, fast-paced environments, but also independent
                      and self-managed. Big believer in knowledge sharing and mentorships, both as mentor and mentee.
                    </p>

                    <div>
                      <div class="relative z-10 order-first mb-3 flex items-center text-normal text-zinc-600 dark:text-zinc-500">
                        <span class="font-semibold">Education</span>
                      </div>
                      <div>
                        <span class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">
                          International Burch University, Information Technologies<br/>
                        </span>
                        <br/>
                      </div>
                    </div>

                    <div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">Data Scientist</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                        At Zizoo, the go-to platform for boat holidays, my job was all about making sense of heaps of data and making sure it worked for us in the best way possible. I looked after our ETL pipelines – that's the tech that pulls in data, sorts it out, and gets it ready for use. It's a bit like being a digital librarian, organizing information so it's easy to find and use.<br/><br/>

                        A big part of my day was diving into this data to figure out what it was telling us. I'd turn numbers and stats into reports and dashboards that were actually easy to understand. Think of them as high-tech, data-filled storybooks that helped our team make smart decisions.<br/><br/> 

                        For the tools of the trade, I relied heavily on Python. It's like the Swiss Army knife of coding, great for tackling all kinds of data tasks. AWS was the backbone, giving us the cloud power we needed. Apache Airflow was my tool for keeping our data processes running smoothly, like a conductor leading an orchestra. And for turning all those numbers into eye-catching, informative visuals, I used Tableau and Metabase.<br/><br/> 

                        My role was really about bringing data to life and making it useful for everyone at Zizoo. It was about turning the raw data into insights that helped us steer the company in the right direction, just like navigating a ship on the open seas.<br/><br/>
                      </div>
                    </div>

                    <div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">Data Engineer / Software Engineer, GoCheckID</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                        At GoCheckID, a company specializing in identity verification, I had a hands-on role keeping our systems running smoothly and smartly. My main job was to take care of the backend lambda functions. These are like the hidden gears of our service, working in the background to make sure everything ticks along without any hitches.<br/><br/>

                        I also got to play around with machine learning, which was super interesting. I worked on developing modules that used this cool tech to better verify people's identities. It was like teaching the system to be a detective, sifting through data to figure out if everything matches up.<br/><br/>

                        For the techy side of things, I used Python a lot because it's like the Swiss Army knife of programming languages – really handy for all kinds of tasks. The Serverless framework was my go-to for managing those lambda functions without getting bogged down in server issues. AWS was the powerhouse that let us do all this stuff in the cloud, and PostgreSQL was where all our data lived, safe and sound.<br/><br/>

                        In this job, I felt a bit like a behind-the-scenes wizard, making sure our identity verification magic worked seamlessly and securely. It was a mix of coding, problem-solving, and keeping up with the latest in machine learning, all to make sure people could trust our service to confirm who they really are.<br/><br/>
                      </div>
                    </div>

                    <div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">Data Engineer / Software Engineer, SHS A.M.</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                        At SHS A.M., a company that blends knowledge and quantitative analysis in asset management, I had the exciting job of looking after and improving our Python-based automatic stock-trading application. Think of it as a smart, automated tool that helps in making quick and smart stock trading decisions.<br/><br/>

                        My daily work involved a lot of hands-on coding and problem-solving. I had to make sure our trading tool was not only running smoothly but also staying ahead of the game. This meant constantly tweaking and upgrading the system, fixing any bugs that popped up, and adding new features to keep up with the fast-paced world of stock trading.<br/><br/>

                        Working with Python was great because it's such a versatile tool for this kind of job. I got to dive deep into the code, figuring out how to make our application more efficient and reliable. It was a bit like being a mechanic fine-tuning a high-performance racing car, except the car was our trading software.<br/><br/>

                        I found it really rewarding to see the direct impact of my work. Every improvement I made helped our application make smarter trading decisions, which was crucial for our company's success. It was a blend of tech and trading that kept me on my toes and made every day interesting.<br/><br/>
                      </div>
                    </div>

                    <div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">Data Engineer / Software Engineer, Yewno</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                        At Yewno, a company that's all about using big data and AI to make a difference in finance, education, and publishing, I had a hands-on role in making our tech work better and faster. My job was to dive into our data pipelines and backend apps, which are like the engines behind our services, and fine-tune them. It was a bit like being a mechanic for digital systems, making sure everything ran smoothly and efficiently.<br/><br/>

                        I used a bunch of different tech tools to get the job done. Python was my go-to for its flexibility in dealing with data. PostgreSQL helped me manage our databases like a pro. AWS was key for keeping everything running in the cloud, which meant we could scale up without missing a beat. Node.js was great for juggling lots of tasks at once without slowing down. And Scala was my secret weapon for some of the more complex programming challenges.<br/><br/>

                        In this role, I felt like a tech detective and problem-solver rolled into one. Every day brought new challenges, but finding ways to make our systems better and seeing the positive impact on our services was super rewarding. It was exciting to be part of a team that's pushing the boundaries of what AI can do in the real world.<br/><br/>
                      </div>
                    </div>

                    <div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">Machine Learning Engineer / Software Engineer, Tapklik</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                        At Tapklik, which later became Audianc, I had the exciting opportunity to work on cutting-edge adtech projects. My main task was to build a machine learning model that could predict how likely people are to click on online ads. It was a challenging yet rewarding task, as it involved crunching numbers and patterns to understand user behavior better.<br/><br/>

                        Another part of my job was to develop an application that made it easy for users to upload and manage large sets of data. This was crucial for our clients who dealt with heaps of information and needed a simple, efficient way to handle it.<br/><br/>

                        To make all this happen, I used a mix of technologies like Python, Apache Spark, Scala, AWS, and MySQL. Each of these played a key role in different parts of the project, from processing large amounts of data quickly to storing information securely. Working with these tools helped me deepen my technical skills and understand how different technologies can come together to create impactful solutions.<br/><br/>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="lg:pl-20">
                  <button v-if='1==0' @click='contactActive=true' type="button" class="content-center rounded-md bg-indigo-600 px-10 py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 w-full">
                    Hire Ivica
                  </button>

                  <div role="list">
                    <div class="mt-8 border-t border-zinc-100 pt-8 dark:border-zinc-700/40">
                      <div style="height: 40px;">
                        <span class="tag border-indigo">Python</span>
                        <span class="tag border-indigo">AWS</span>
                        <span class="tag border-indigo">Docker</span>
                        <span class="tag border-indigo">PostgreSQL</span>
                      </div>
                      <div style="height: 40px;">
                        <span class="tag border-indigo">Data analysis</span>
                        <span class="tag border-indigo">Data processing</span>
                        <span class="tag border-indigo">Git</span>
                      </div>
                      <div style="height: 40px;">
                        <span class="tag border-indigo">Apache ecosystem</span>
                        <span class="tag border-indigo">Spark</span>
                        <span class="tag border-indigo">Zeppelin</span>
                      </div>
                      <div style="height: 40px;">
                        <span class="tag border-indigo">Strong Analytical and Critical Thinking</span>
                      </div>
                      <div style="height: 40px;">
                        <span class="tag border-indigo">Airflow</span>
                        <span class="tag border-indigo">Problem Solving</span>
                        <span class="tag border-indigo">Leadership</span>
                      </div>
                    </div>
                  </div>

                  <div role="list" class="mt-4">
                    <span class="font-semibold">Languages:</span>
                    <div class="mt-4 border-t border-zinc-100 pt-4 dark:border-zinc-700/40">
                      <div style="height: 40px;">
                        <span class="tag" style="border-color: green; color: green">English</span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <div v-if='contactActive' class="contact-section">
      <Contact />
    </div>

  </div>
</template>

<script>
import Contact from "@/views/employee/Contact"

export default {
  name: 'Employee-page-ivica',
  components: {
    Contact
  },
  data () {
    return {
      contactActive: false
    }
  },

  methods: {

  },

  mounted () {
    
  }
}
</script>

<style lang="scss" scoped>

.tag {
  margin-right: 15px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #2764EA;
  border-radius: 15px;
  color: #2764EA;
}

.contact-section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.exp {
  margin-top: 10px;
  font-weight: 500;
  color: #2764EA;
}

</style>