<template>
  <div class="employee">
    <main class="flex-auto">
      <div class="sm:px-8 mt-16 sm:mt-32">
        <div class="mx-auto w-full max-w-7xl lg:px-8">
          <div class="relative px-4 sm:px-8 lg:px-12">
            <div class="mx-auto max-w-2xl lg:max-w-5xl">
              <div class="grid grid-cols-1 gap-y-16 lg:grid-cols-2 lg:grid-rows-[auto_1fr] lg:gap-y-12">
                <div class="lg:pl-20">
                  <div class="max-w-xs px-2.5 lg:max-w-none">
                    <img alt="" loading="lazy" width="800" height="800" decoding="async" data-nimg="1" class="aspect-square rotate-3 rounded-2xl bg-zinc-100 object-cover dark:bg-zinc-800" sizes="(min-width: 1024px) 32rem, 20rem" src="@/assets/employees/senad.jpg" style="color: transparent;">
                  </div>
                </div>
                <div class="lg:order-first lg:row-span-2">
                  <h1 class="text-4xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
                    Senad Kurtović, <br/> Medior - Full stack engineer.
                  </h1>

                  <div class="exp">
                    3+ years of professional experience
                  </div>

                  <div class="ver text-sm text-green-600">
                    <div class="mt-1 ">
                      <svg  style='display: inline;margin-top: -3px;' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                      </svg>
                      Verified in PHP (Laravel, Lumen)
                    </div>
                    <div class="mt-0.5">
                      <svg  style='display: inline;margin-top: -3px;' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                      </svg>
                      Verified in Javascript
                    </div>
                  </div>

                  <div class="mt-6 mb-8 space-y-7 text-base text-zinc-600 dark:text-zinc-400">
                    <p>
                      Full Stack Developer with a deep-seated passion for crafting exceptional, user-centric web applications. With three years of extensive experience in front-end development, I possess a profound understanding of HTML, CSS, SCSS, Javascript, and Vue.js, which empowers me to create dynamic and responsive user interfaces that stand out in both functionality and aesthetics. For back-end technologies, I use PHP and Laravel, to provide a more comprehensive suite of services to my clients and contribute more effectively to my team.<br/><br/>

                      My professional journey is marked by a commitment to staying abreast of the latest technological advancements and industry trends, ensuring that the solutions I deliver are both innovative and relevant. I have a proven track record of collaborating effectively with cross-functional teams, ensuring projects are delivered with precision, on time, and within budget constraints. My approach to development is always client-focused, aiming to exceed expectations and drive business success through superior web solutions.
                    </p>

                    <div>
                      <div class="relative z-10 order-first mb-3 flex items-center text-normal text-zinc-600 dark:text-zinc-500">
                        <span class="font-semibold">Education</span>
                      </div>
                      <div>
                        <span class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">
                          Faculty of Natural Sciences and Mathematics, University of Sarajevo
                        </span>
                        <br/>
                      </div>
                    </div>

                    <div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">Zima Blue Software - Full Stack Engineer</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                        Professional Experience and Expertise:<br/><br/>

                        I had the amazing opportunity to work on a site generator project, something similar to the likes of Wix and Squarespace. This wasn't just any project – we were building a tool that would let people easily create their own websites. For the tech side of things, we used Laravel for the backend, which is great for its elegant structure and awesome features. And for the frontend, we chose Vue.js, which is super versatile and great for making interactive interfaces.<br/><br/>

                        This project was a team effort, and I was part of a group of six developers. We really had to pull together, communicate effectively, and tackle all sorts of challenges head-on. It was all about teamwork and blending our different skills and ideas to make something great.<br/><br/>

                        On top of that, I worked on Pin.it, a social platform that's a bit like Facebook but with a twist – it includes real-time location features. Imagine a social network where you can connect with others based on where you are at the moment. The backend of this platform was built with Lumen, which is a lighter but still powerful version of Laravel, and it was perfect for handling all the live, real-time data we were dealing with. For the frontend, we used React, which was ideal for creating a smooth and responsive user experience, especially with all the live updates and interactions on the platform.<br/><br/>

                        Working on Pin.it was not just about coding; it was about thinking creatively, planning strategically, and being ready to adapt to new challenges. It really stretched my skills in full-stack development and pushed me to stay ahead in the fast-paced world of tech.
                      </div>
                    </div>

                    <div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">From Software - Full-stack Developer</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                        I've had the exciting challenge of designing and building several Single Page Applications (SPAs). These projects were all about creating user-friendly, responsive experiences that keep everything on a single, dynamic page.<br/><br/>

                        One of my favorite projects was adding a neat pagination feature to “Hackernews”. I included filter options and categories, making it much easier for users to find what they're looking for.<br/><br/>

                        I also dived into e-commerce by developing a shopping site. It had everything from a handy cart feature to smart use of local storage, all aimed at making online shopping a breeze for users.<br/><br/>

                        I created a website where users can explore every country in the world. On the main page, you get a snapshot of each country, and if you want to dig deeper, there's a second page with more detailed info, all pulled in through API calls.<br/><br/>

                        I've even put together a Single Page Application for myself, a “MyPortfolio” site. It’s a personal showcase of the work I’ve done and the skills I’ve honed.<br/><br/>

                        I had the unique opportunity to work on a project for the UN. The challenge here was to build web pages using only the basics - HTML, CSS, and JavaScript, without any libraries. It was all about going back to the roots of web development and creating something clean and functional.<br/><br/>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="lg:pl-20">
                  <button v-if='1==0' @click='contactActive=true' type="button" class="content-center rounded-md bg-indigo-600 px-10 py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 w-full">
                    Hire Senad
                  </button>

                  <div role="list">
                    <div class="mt-8 border-t border-zinc-100 pt-8 dark:border-zinc-700/40">
                      <div style="height: 40px;">
                        <span class="tag border-indigo">PHP</span>
                        <span class="tag border-indigo">Laravel</span>
                        <span class="tag border-indigo">JavaScript</span>
                        <span class="tag border-indigo">Vue.js</span>
                      </div>
                      <div style="height: 40px;">
                        <span class="tag border-indigo">jQuery</span>
                        <span class="tag border-indigo">Bootstrap</span>
                        <span class="tag border-indigo">CSS</span>
                        <span class="tag border-indigo">SQL</span>
                      </div>
                      <div style="height: 40px;">
                        <span class="tag border-indigo">HTML</span>
                        <span class="tag border-indigo">Tailwind</span>
                        <span class="tag border-indigo">Restful APIs</span>
                        <span class="tag border-indigo">Git</span>
                      </div>
                    </div>
                  </div>

                  <div role="list" class="mt-4">
                    <span class="font-semibold">Languages:</span>
                    <div class="mt-4 border-t border-zinc-100 pt-4 dark:border-zinc-700/40">
                      <div style="height: 40px;">
                        <span class="tag" style="border-color: green; color: green">English</span>
                        <span class="tag" style="border-color: green; color: green">Turkish</span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <div v-if='contactActive' class="contact-section">
      <Contact />
    </div>

  </div>
</template>

<script>
import Contact from "@/views/employee/Contact"

export default {
  name: 'Employee-page-senad',
  components: {
    Contact
  },
  data () {
    return {
      contactActive: false
    }
  },

  methods: {

  },

  mounted () {
    
  }
}
</script>

<style lang="scss" scoped>

.tag {
  margin-right: 15px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #2764EA;
  border-radius: 15px;
  color: #2764EA;
}

.contact-section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.exp {
  margin-top: 10px;
  font-weight: 500;
  color: #2764EA;
}

</style>