<template>
  <div class="employee">
    <main class="flex-auto">
      <div class="sm:px-8 mt-16 sm:mt-32">
        <div class="mx-auto w-full max-w-7xl lg:px-8">
          <div class="relative px-4 sm:px-8 lg:px-12">
            <div class="mx-auto max-w-2xl lg:max-w-5xl">
              <div class="grid grid-cols-1 gap-y-16 lg:grid-cols-2 lg:grid-rows-[auto_1fr] lg:gap-y-12">
                <div class="lg:pl-20">
                  <div class="max-w-xs px-2.5 lg:max-w-none">
                    <img alt="" loading="lazy" width="800" height="800" decoding="async" data-nimg="1" class="aspect-square rotate-3 rounded-2xl bg-zinc-100 object-cover dark:bg-zinc-800" sizes="(min-width: 1024px) 32rem, 20rem" src="@/assets/employees/edin.jpg" style="color: transparent;">
                  </div>
                </div>
                <div class="lg:order-first lg:row-span-2">
                  <h1 class="text-4xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
                    Edin Sitarevic <br/> Full stack engineer, consultant, CTO.
                  </h1>
                  
                  <div class="exp">
                    10+ years of professional experience
                  </div>

                  <div class="ver text-sm text-green-600">
                    <div class="mt-1 ">
                      <svg  style='display: inline;margin-top: -3px;' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                      </svg>
                      Verified in PHP (Laravel, Lumen, CodeIgniter)
                    </div>
                    <div class="mt-0.5">
                      <svg  style='display: inline;margin-top: -3px;' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                      </svg>
                      Verified in Javascript (Vue, React, Node)
                    </div>
                    <div class="mt-0.5">
                      <svg  style='display: inline;margin-top: -3px;' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                      </svg>
                      Verified in Python (Django, AI)
                    </div>
                    <div class="mt-0.5">
                      <svg style='display: inline;margin-top: -3px;' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
                      </svg>
                      Team Lead
                    </div>
                  </div>

                  <div class="mt-6 mb-8 space-y-7 text-base text-zinc-600 dark:text-zinc-400">
                    <p>
                      Edin Sitarevic is a software engineer with more than 10 years of experience. Edin is what you would call a full stack developer. 
                      He is responsible for creating start to finish solutions of project. That includes creating database relations, structure and setting mindset for a project. 
                      He also develops everything needed for back-end and front-end side of project (from creating foundation to project release). Edin is also experienced with ui/ux side of a project. 
                      As a coworker he prove himself numerous times and and he is well received by colleagues he works with.
                    </p>

                    <div>
                      <div class="relative z-10 order-first mb-3 flex items-center text-normal text-zinc-600 dark:text-zinc-500">
                        <span class="font-semibold">Education</span>
                      </div>
                      <div>
                        <span class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">
                          University of Buckingham (SSST), Buckingham England (Sarajevo) - Computer Science(major), Electrical Engineering(minor)
                        </span>
                        <br/>
                        <a href="https://www.buckingham.ac.uk/" target="_blank" class="text-blue-400">https://www.buckingham.ac.uk/</a>
                      </div>
                    </div>

                    <div>
                      <div class="relative z-10 order-first mb-3 flex items-center text-sm text-zinc-400 dark:text-zinc-500">September 2021 - Present</div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">Medosync (META) - Dublin(Ireland) - Front-end Tech Lead</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                        As a Front-End Tech Lead, I played a pivotal role in the development of a private hospital system, comprising both web and mobile applications, 
                        catering to a majority of hospitals in Ireland and Germany. <br/><br/>

                        This comprehensive solution streamlines the management of patients, doctors, hospitals, and other crucial aspects of healthcare administration. 
                        In my capacity as Tech Lead, I was responsible for guiding the front-end development team, ensuring the implementation of best practices, and maintaining the overall quality of the codebase.<br/><br/>

                        The web platform was developed using Vue.js, while the mobile app was created with Vue.js and wrapped with Cordova to ensure cross-platform compatibility. 
                        This approach enabled a consistent user experience across both web and mobile platforms, making it easier for hospital staff to access and manage information on the go.<br/><br/>

                        My leadership role also involved coordinating with other teams, setting project milestones, and providing technical guidance to junior developers. 
                        I ensured that our team met the project's objectives and delivered a user-friendly, efficient, and scalable solution that met the needs of hospitals, doctors, and patients alike.<br/><br/>

                        In addition to working on the current Vue.js-based mobile app, I contributed to the development of the first version, which was built using React.js. 
                        Working as the Front-End Tech Lead for this private hospital system has provided me with invaluable experience in managing complex, large-scale projects in the healthcare sector. 
                        My leadership and technical abilities have been instrumental in delivering high-quality software that improves the efficiency of healthcare administration and elevates patient care standards.
                      </div>
                    </div>

                    <div>
                      <div class="relative z-10 order-first mb-3 flex items-center text-sm text-zinc-400 dark:text-zinc-500">September 2018 - September 2021</div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">The Chive - Los Angeles(SAD) - Full-Stack Engineer</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                        I have had the privilege of working on a diverse range of projects within the Chive Media Group, including Atmosphere.tv (https://atmosphere.tv/), 
                        The Chive (https://thechive.com/), and the Chive Media Group website (https://www.chivemediagroup.com/). 
                        Atmosphere is the world's largest streaming TV service for businesses, featuring original, audio-optional TV channels tailored to various industries and audiences. 
                        As one of the largest websites in America, The Chive is a popular digital media platform that offers a unique blend of humor, entertainment, and lifestyle content. <br/><br/>

                        In my role, I collaborated with a talented team of developers, designers, 
                        and product managers to enhance and optimize these platforms using cutting-edge technologies such as Vue.js, Next.js, and Laravel. 
                        I was responsible for developing and implementing new features, improving site performance, and ensuring that each platform provided an exceptional user experience.<br/><br/>

                        The web platform was developed using Vue.js, while the mobile app was created with Vue.js and wrapped with Cordova to ensure cross-platform compatibility. 
                        This approach enabled a consistent user experience across both web and mobile platforms, making it easier for hospital staff to access and manage information on the go.<br/><br/>

                        My work on these projects involved a high degree of attention to detail, as well as the ability to adapt to changing requirements and prioritize tasks effectively. 
                        I contributed to various aspects of the development lifecycle, from ideation and planning to testing and deployment, while also ensuring that each platform remained secure and scalable.<br/><br/>

                        Working on these high-profile projects has given me invaluable experience in managing the unique challenges and demands that come with developing and maintaining large-scale digital platforms. 
                        I have honed my skills in Vue.js, Next.js, and Laravel, and have become adept at utilizing these technologies to create innovative 
                        solutions that meet the evolving needs of users and businesses alike.
                      </div>
                    </div>

                    <div>
                      <div class="relative z-10 order-first mb-3 flex items-center text-sm text-zinc-400 dark:text-zinc-500">October 2015 - September 2018</div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">Rare Carat(Millennium Software Inc.) - Michigan(SAD) - Front-End Engineer</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                        I was instrumental in working on the new version of Rare Carat (https://www.rarecarat.com), a leading diamond search engine and marketplace, 
                        known for its extensive selection of diamonds and user-friendly platform. The project entailed a complete overhaul of both the front-end and back-end systems, 
                        employing cutting-edge technologies such as Vue.js for the front-end and C# for the back-end to enhance performance, improve usability, 
                        and provide a seamless experience to the users.<br/><br/>

                        My role in the project encompassed various responsibilities, 
                        including designing and implementing new features, optimizing existing functionalities, 
                        and ensuring that the platform remained secure, reliable, and scalable. As part of a dynamic team, I collaborated with other developers, 
                        designers, and product managers to ensure the successful delivery of the revamped Rare Carat platform.<br/><br/>

                        In addition to my work on Rare Carat, I contributed to several smaller React projects, 
                        demonstrating my ability to adapt to different technologies and work on a diverse range of projects. 
                        These experiences not only helped me refine my technical skills but also fostered my growth as a versatile software 
                        developer capable of tackling complex challenges and delivering high-quality solutions.<br/><br/>

                        Throughout my tenure, I remained committed to staying current with the latest industry trends and best practices, 
                        enabling me to make informed decisions and drive innovation within the projects I was involved in. 
                        This experience has significantly contributed to my expertise in software development, particularly in the realms of Vue.js, C#, and React.
                      </div>
                    </div>

                    <div>
                      <div class="relative z-10 order-first mb-3 flex items-center text-sm text-zinc-400 dark:text-zinc-500">November 2013 - October 2015</div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">NEST71, Sarajevo— Lead Full Stack Software Engineer</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                        For 2 years, I served as the Lead Software Developer on three innovative startups: BalkanVibe, Bookvar, and Habeetat-Beehive.<br/><br/>
                        As part of the 15-member BalkanVibe team, I helped develop a travel platform for the Western Balkan region, showcasing tours and attractions.<br/><br/>

                        At Bookvar, I contributed to creating an online learning platform akin to Udemy, specifically designed for the Balkan audience.<br/><br/>

                        Lastly, I played a significant role in the development of Habeetat-Beehive, a project focused on smart beehives, 
                        allowing users to monitor their hives' conditions, such as honey quantity, temperature, and occupancy, through a dedicated website.<br/><br/>
                      </div>
                    </div>

                    <!-- Projects -->
                    <div>
                      <div class="text-xl font-semibold tracking-tight text-zinc-500 dark:text-zinc-100 mb-11">Projects</div>
                    </div>

                    <div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">BalkanVibe: Premier Travel Platform for the Western Balkan</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                        <ul style="list-style-type: disc;padding-left: 20px;">
                          <li style="margin-bottom: 5px;">Spearheaded the development of BalkanVibe, a prominent startup backed by the Swedish government, dedicated to showcasing tours and attractions throughout the Western Balkan region.</li>
                          <li style="margin-bottom: 5px;">Pioneered a proprietary payment system, scalable and adaptable for integration on external websites.</li>
                          <li style="margin-bottom: 5px;">Engaged in a vast project development using a diverse technology stack, including Laravel 5, Vue.js, Angular.js, JavaScript, HTML, CSS, and MySQL.</li>
                          <li style="margin-bottom: 5px;">Held a pivotal role as the Lead Developer, showcasing technical leadership and project management capabilities.</li>
                          <li style="margin-bottom: 5px;">Currently maintain a vested interest in the company's future and growth as a shareholder.</li>
                        </ul>
                      </div>
                    </div>

                    <div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">Habeetat-Beehive - Smart Beehive Monitoring System</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                        <ul style="list-style-type: disc;padding-left: 20px;">
                          <li style="margin-bottom: 5px;">Pioneered the development of a cutting-edge monitoring system that allows users to seamlessly link and manage their beehives through a dedicated web platform.</li>
                          <li style="margin-bottom: 5px;">Enabled real-time tracking of essential hive metrics such as honey quantity, internal temperature, and occupancy status (from empty to full).</li>
                          <li style="margin-bottom: 5px;">Implemented using a robust technology stack, including Sails.js for backend services, React for frontend development, and MongoDB for database management.</li>
                        </ul>
                      </div>
                    </div>

                    <div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">uQuest - Cryptocurrency Gaming Platform</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                        <ul style="list-style-type: disc;padding-left: 20px;">
                          <li style="margin-bottom: 5px;">Developed and launched uQuest, an interactive website dedicated to the Influence cryptocurrency, where users can engage in a proprietary game.</li>
                          <li style="margin-bottom: 5px;">Successfully designed a reward mechanism, allowing users to earn Influence points upon game progression, which can then be exchanged or utilized within the platform.</li>
                          <li style="margin-bottom: 5px;">Leveraged pure canvas for the web design and integrated the Phaser.js library to optimize the gaming experience.</li>
                          <li style="margin-bottom: 5px;">Solely responsible for the website's end-to-end development, showcasing technical proficiency and project ownership.</li>
                        </ul>
                      </div>
                    </div>

                    <div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">Consortium Immobilier</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                        <ul style="list-style-type: disc;padding-left: 20px;">
                          <li style="margin-bottom: 5px;">Engineered an internal administrative panel featuring a bespoke calendar interface reminiscent of Google's design.</li>
                          <li style="margin-bottom: 5px;">Utilized Vuetify.js, a Material Design component framework, for a responsive and modern frontend experience.</li>
                          <li style="margin-bottom: 5px;">Integrated Lumen for efficient backend operations, ensuring a seamless user experience and robust system performance.</li>
                        </ul>
                      </div>
                    </div>

                    <div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">Online Doctor Consultation Platform</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                        <ul style="list-style-type: disc;padding-left: 20px;">
                          <li style="margin-bottom: 5px;">Spearheaded the development of a digital consultation platform, enabling users to connect with medical professionals for expert advice via direct video calls.</li>
                          <li style="margin-bottom: 5px;">Incorporated a dynamic billing system where consultation time was proportionate to the payment made.</li>
                          <li style="margin-bottom: 5px;">Utilized a robust tech stack including Laravel 5, Vue.js, HTML, CSS, and MySQL to ensure seamless user experience.</li>
                          <li style="margin-bottom: 5px;">Seamlessly integrated the OBS streaming system for efficient and high-quality video consultations.</li>
                        </ul>
                      </div>
                    </div>

                    <div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">Unicredit Bank - Champions League Engagement</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                        <ul style="list-style-type: disc;padding-left: 20px;">
                          <li style="margin-bottom: 5px;">Developed a comprehensive online engagement platform for UniCredit Bank to enhance user interaction and brand loyalty.</li>
                          <li style="margin-bottom: 5px;">Users could participate in quizzes, engage in in-game money transactions, and plan trips, simulating real-life banking experiences.</li>
                          <li style="margin-bottom: 5px;">Implemented a reward mechanism where correct answers yielded in-game currency.</li>
                          <li style="margin-bottom: 5px;">Integrated a leaderboard system that awarded the top participants with actual monetary rewards from the bank.</li>
                          <li style="margin-bottom: 5px;">Utilized technologies including Laravel 5, JavaScript, HTML, CSS, and MySQL for the seamless functionality of the platform.</li>
                        </ul>
                      </div>
                    </div>

                    <div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">BH-Telecom support system</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                        <ul style="list-style-type: disc;padding-left: 20px;">
                          <li style="margin-bottom: 5px;">Designed and developed an online FAQ platform available as both a Facebook application and a standalone website.</li>
                          <li style="margin-bottom: 5px;">Leveraged Laravel 5 as the core framework, facilitating seamless integration with the OSTicket system.</li>
                          <li style="margin-bottom: 5px;">Established a dual-server architecture.</li>
                          <li style="margin-bottom: 5px;">One server hosted the Laravel application, acting as an intermediary between the Facebook application and OSTicket.</li>
                          <li style="margin-bottom: 5px;">The second server exclusively dedicated to OSTicket, ensuring efficient ticket management and processing.</li>
                        </ul>
                      </div>
                    </div>

                    

                  </div>
                </div>
                <div class="lg:pl-20">
                  <button v-if='1==0' @click='contactActive=true' class="content-center rounded-md bg-indigo-600 px-10 py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 w-full">
                    Hire Edin
                  </button>

                  <div role="list">
                    <div class="mt-8 border-t border-zinc-100 pt-8 dark:border-zinc-700/40">
                      <div style="height: 40px;">
                        <span class="tag border-indigo">PHP</span>
                        <span class="tag border-indigo">Laravel</span>
                        <span class="tag border-indigo">Lumen</span>
                        <span class="tag border-indigo">JavaScript</span>
                        
                      </div>
                      <div style="height: 40px;">
                        <span class="tag border-indigo">Vue.js</span>
                        <span class="tag border-indigo">React.js</span>
                        <span class="tag border-indigo">Next.js</span>
                        <span class="tag border-indigo">Vuetify.js</span>
                      </div>
                      <div style="height: 40px;">
                        <span class="tag border-indigo">Quasar.js</span>
                        <span class="tag border-indigo">MySQL</span>
                        <span class="tag border-indigo">CSS</span>
                        <span class="tag border-indigo">Phaser.io</span>
                      </div>
                      <div style="height: 40px;">
                        <span class="tag border-indigo">HTML</span>
                        <span class="tag border-indigo">Bootstrap</span>
                        <span class="tag border-indigo">Apache</span>
                        <span class="tag border-indigo">CSS</span>
                      </div>
                    </div>
                  </div>

                  <div role="list" class="mt-4">
                    <span class="font-semibold">Languages:</span>
                    <div class="mt-4 border-t border-zinc-100 pt-4 dark:border-zinc-700/40">
                      <div style="height: 40px;">
                        <span class="tag" style="border-color: green; color: green">English</span>
                      </div>
                      <div style="height: 40px;">
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <div v-if='contactActive' class="contact-section">
      <Contact />
    </div>
    


  </div>
</template>

<script>
import Contact from "@/views/employee/Contact"

export default {
  name: 'Employee-page',
  components: {
    Contact
  },
  data () {
    return {
      contactActive: false
    }
  },

  methods: {

  },

  mounted () {
    
  }
}
</script>

<style lang="scss" scoped>

.tag {
  margin-right: 15px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #2764EA;
  border-radius: 15px;
  color: #2764EA;
}

.contact-section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.exp {
  margin-top: 10px;
  font-weight: 500;
  color: #2764EA;
}

</style>