<template>
  
  <div class="dashboard-section">

   <div class="flex flex-1 flex-col md:pl-64">
     <main class="flex-1">
       <div class="py-6">
         <div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
           <h1 class="text-2xl font-semibold text-gray-900" style="padding-bottom: 15px;border-bottom: 1px solid #eee;">Expenses</h1>
         </div>

         <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
           <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
             <form class="space-y-6" action="#" method="POST">
               <div style="overflow: auto;">

                <label class="block text-sm font-medium text-gray-700 mt-2">Name</label>
                <div class="mt-1">
                 <input v-model='expense.name' type="text" required class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm float-left">
                </div>

                <div style="clear: both;"></div>

                <label class="mt-2 block text-sm font-medium text-gray-700">Amount</label>
                <div class="mt-1">
                 <input v-model='expense.amount' type="number" required class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm float-left">
                </div>

                <div style="clear: both;"></div>

                <label for="location" class="mt-2 block text-sm font-medium text-gray-700">Direction</label>
                <select v-model='expense.sign' class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                  <option v-for='(d, index) in directions' :key='index' :value='d'>{{d}}</option>
                </select>

               </div>

               <div style="clear: both;"></div>

               <div class="mt-4">
                 <div @click='addExpense()' class="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" style="cursor: pointer;">Add</div>
               </div>

             </form>

           </div>
         </div>



         <div class="px-4 sm:px-6 lg:px-8 mb-8">
           <div class="sm:flex sm:items-center">
             <div class="sm:flex-auto">
               <h1 class="text-base font-semibold leading-6 text-gray-900">Za trenutni mjesec</h1>
               <p class="mt-2 text-sm text-gray-700">Lista dobiti i troskova.</p>
             </div>
           </div>
           <div class="mt-8 flow-root">
             <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
               <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                 <table v-if='expenses' class="min-w-full divide-y divide-gray-300">
                   <thead>
                     <tr>
                       <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">Ime</th>
                       <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Iznos</th>
                       <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Dobit/Trosak</th>
                       <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Action</th>
                     </tr>
                   </thead>
                   <tbody class="bg-white">
                     <tr v-for='(expense, index) in month' :key='index' class="expense-li" :class="{'plus': expense.sign == 'plus'}">
                       <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-3">{{expense.name}}</td>
                       <td class="whitespace-nowrap px-3 py-4 text-sm">{{expense.amount}}</td>
                       <td class="whitespace-nowrap px-3 py-4 text-sm">{{expense.sign}}</td>
                       <td class="whitespace-nowrap px-3 py-4 text-sm">
                         <button type="button" class="rounded bg-red-600 py-1.5 px-2 text-xs font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600" @click='deleteExpense(expense.id)'>Delete</button>
                       </td>
                     </tr>

                     <tr>
                      <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-3 text-green-600">
                        Dobit trenutni mjesec:
                        {{monthPlus.toLocaleString('en-US')}} BAM
                      </td>
                      <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-3 text-red-600">
                        Trosak trenutni mjesec:
                        {{monthMinus.toLocaleString('en-US')}} BAM
                      </td>
                     </tr>
                   </tbody>
                 </table>
               </div>
             </div>
           </div>
         </div>




         <div class="px-4 sm:px-6 lg:px-8 mt-8">
           <div class="sm:flex sm:items-center">
             <div class="sm:flex-auto">
               <h1 class="text-base font-semibold leading-6 text-gray-900">Racuni</h1>
               <p class="mt-2 text-sm text-gray-700">Lista dobiti i troskova.</p>
             </div>
           </div>
           <div class="mt-8 flow-root">
             <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
               <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                 <table v-if='expenses' class="min-w-full divide-y divide-gray-300">
                   <thead>
                     <tr>
                       <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">Ime</th>
                       <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Iznos</th>
                       <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Dobit/Trosak</th>
                       <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Action</th>
                     </tr>
                   </thead>
                   <tbody class="bg-white">
                     <tr v-for='(expense, index) in expenses' :key='index' class="expense-li" :class="{'plus': expense.sign == 'plus'}">
                       <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-3">{{expense.name}}</td>
                       <td class="whitespace-nowrap px-3 py-4 text-sm">{{expense.amount}}</td>
                       <td class="whitespace-nowrap px-3 py-4 text-sm">{{expense.sign}}</td>
                       <td class="whitespace-nowrap px-3 py-4 text-sm">
                         <button type="button" class="rounded bg-red-600 py-1.5 px-2 text-xs font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600" @click='deleteExpense(expense.id)'>Delete</button>
                       </td>
                     </tr>

                     <tr>
                      <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-3 text-green-600">
                        Ukupna Dobit:
                        {{expenses.totalPlus.toLocaleString('en-US')}} BAM
                      </td>
                      <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-3 text-red-600">
                        Ukupni Trosak:
                        {{expenses.totalMinus.toLocaleString('en-US')}} BAM
                      </td>
                     </tr>
                   </tbody>
                 </table>
               </div>
             </div>
           </div>
         </div>





       </div>
     </main>
   </div>

  </div>

</template>

<script>
import api from '@/api/api'

export default {
  name: 'Expenses-view',
  components: {

  },
  data () {
    return {
      expense: {
        name: '',
        amount: 0,
        sign: 'plus'
      },
      directions: ['plus', 'minus'],
      expenses: null,
      month: null,
      monthPlus: 0,
      monthMinus: 0
    }
  },

  methods: {
    async addExpense () {
      let response = await api.addExpese(this.expense)
      console.log('response', response)
      if (response.request.status < 300) {
        console.log('test')
        this.$toast.success('Dodano!')
        this.resetExpense()
        this.getExpese()
      } else {
        console.log('error')
      }
    },

    async getExpese () {
      let response = await api.getExpese(this.expense)
      console.log('as', response.data)
      if (response.request.status < 300) {

        response.data.totalPlus = 0
        response.data.totalMinus = 0

        response.data.map(item => {
          if (item.sign == 'plus') {
            response.data.totalPlus += parseFloat(item.amount)
          } else {
            response.data.totalMinus += parseFloat(item.amount)
          }
        })


        console.log('response.data', response.data.totalMinus)

        this.expenses = response.data
        console.log('response.data', this.expenses)
      } else {
        console.log('error')
      }
    },

    async getExpensesThisMonth () {
      let response = await api.getExpensesThisMonth()
      console.log('MONTH', response.data)
      if (response.request.status < 300) {

        response.data.map(item => {
          if (item.sign == 'plus') {
            this.monthPlus += parseFloat(item.amount)
          } else {
            this.monthMinus += parseFloat(item.amount)
          }
        })

        this.month = response.data
        console.log('response.data', this.month)
      } else {
        console.log('error')
      }
    },

    async deleteExpense (id) {
      let response = await api.deleteExpense(id)
      console.log('response', response)
      if (response.request.status < 300) {
        this.$toast.success('Izbrisano!')
        this.getExpese()
      } else {
        console.log('error')
      }
    },

    resetExpense () {
      this.expense.name = ''
      this.expense.amount = 0
      this.expense.sign = 'plus'
    }
  },

  mounted () {
    this.getExpese()
    this.getExpensesThisMonth()
  }
}
</script>

<style lang="scss" scoped>

  select {
    border: 1px solid #dedede;
  }

  .expense-li {
    font-weight: 600;
    border-bottom: 1px solid #eee;
    background: rgb(255, 0, 0, 0.05);
    color: #000;
  }

  .plus {
    background: rgb(0, 255, 0, 0.08);
  }

</style>