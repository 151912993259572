<template>
  <div class="employee">
    <main class="flex-auto">
      <div class="sm:px-8 mt-16 sm:mt-32">
        <div class="mx-auto w-full max-w-7xl lg:px-8">
          <div class="relative px-4 sm:px-8 lg:px-12">
            <div class="mx-auto max-w-2xl lg:max-w-5xl">
              <div class="grid grid-cols-1 gap-y-16 lg:grid-cols-2 lg:grid-rows-[auto_1fr] lg:gap-y-12">
                <div class="lg:pl-20">
                  <div class="max-w-xs px-2.5 lg:max-w-none">
                    <img alt="" loading="lazy" width="800" height="800" decoding="async" data-nimg="1" class="aspect-square rotate-3 rounded-2xl bg-zinc-100 object-cover dark:bg-zinc-800" sizes="(min-width: 1024px) 32rem, 20rem" src="@/assets/employees/sadin.jpg" style="color: transparent;">
                  </div>
                </div>
                <div class="lg:order-first lg:row-span-2">
                  <h1 class="text-4xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
                    Sadin Pita, <br/> Senior Full stack engineer.
                  </h1>

                  <div class="exp">
                    7+ years of professional experience
                  </div>

                  <div class="ver text-sm text-green-600">
                    <div class="mt-1 ">
                      <svg  style='display: inline;margin-top: -3px;' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                      </svg>
                      Verified in PHP (Laravel, Lumen)
                    </div>
                    <div class="mt-0.5">
                      <svg  style='display: inline;margin-top: -3px;' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                      </svg>
                      Verified in Javascript (Vue, React, Node)
                    </div>
                    <div class="mt-0.5">
                      <svg  style='display: inline;margin-top: -3px;' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                      </svg>
                      Verified in Python (Django)
                    </div>
                    <div class="mt-0.5">
                      <svg style='display: inline;margin-top: -3px;' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
                      </svg>
                      Team Lead
                    </div>
                  </div>

                  <div class="mt-6 mb-8 space-y-7 text-base text-zinc-600 dark:text-zinc-400">
                    <p>
                      Born in the vibrant city of Munich, Germany, Sadin now resides in Sarajevo, Bosnia and Herzegovina—a locale where he pursued his academic journey, culminating in a specialized degree from the High School of Electrical Engineering with a focus on Computing and Informatics. Furthering his education, he attended the Faculty of Natural Sciences and Mathematics at the University of Sarajevo.<br/><br/>

                      From a young age, Sadin's inquisitiveness and creative spirit were evident. His intrinsic passion for technology and informatics propelled him into a realm of continuous self-learning, allowing him to traverse a spectrum of technologies. His diverse portfolio showcases an array of accomplishments: from the conceptualization and development of web and desktop applications to the intricate world of video game design and modification. Moreover, his foray into the domain of graphic design and digital art underscores his multifaceted skills and keen aesthetic sense.<br/><br/>

                      Throughout his career, Sadin has consistently blended technical acumen with artistic flair, establishing himself as a dynamic force in the world of technology and design.<br/><br/>
                    </p>

                    <div>
                      <div class="relative z-10 order-first mb-3 flex items-center text-normal text-zinc-600 dark:text-zinc-500">
                        <span class="font-semibold">Education</span>
                      </div>
                      <div>
                        <span class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">
                          Faculty of Natural Sciences and Mathematics, University of Sarajevo
                        </span>
                        <br/>
                      </div>
                    </div>

                    <div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">Walter Code - Sarajevo(Bih) - Lead Engineer</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                        Professional Experience and Expertise:<br/><br/>

                        Proficiently utilized Vue.js, spearheading UI/UX design solutions, leading client demo sessions, and showcasing team progress.<br/>
                        Experienced with Node.js API and MySQL, demonstrating a strong grasp of backend technologies.<br/>
                        Successfully executed multiple WordPress projects, crafting custom solutions from scratch.<br/>
                        Collaborated with international clients, emphasizing the global reach of projects undertaken.<br/>
                        Agile team experience using tools like Jira and Confluence to manage projects seamlessly.<br/>
                        Displayed expertise in creating pixel-perfect design solutions, ensuring premium user experiences.<br/>
                        Proficient in Adobe XD and Figma with experience in Adobe Photoshop, underscoring diverse design skills.<br/>
                        Contributed to global projects as a Frontend Developer in international teams, showcasing adaptability and collaboration.<br/>
                        Currently holding the role of Frontend Lead (React) for almost two years, responsible for project initiation, concept realization, and mentoring peers in frontend best practices.<br/>

                        <br/>
                        Soft Skills and Personal Attributes:<br/><br/>

                        Driven by a desire to deliver the best possible experience in every project, aiming to leave a lasting impression.<br/>
                        Boasts a strong work ethic and a passion for creating and developing innovative projects and features.<br/>
                        A detailed-oriented creative individual, reflected in the choice to specialize in frontend technologies.<br/>
                        Always eager to tackle challenges and engage in problem-solving tasks.<br/>
                        Demonstrates a robust client presentation and visual communication style, complemented by excellent verbal and written skills.<br/>
                        Equally adept at working individually or collaboratively, with experience in solo and team projects.<br/>
                        Leadership-oriented, taking responsibility for teams and projects while setting clear expectations and maintaining high quality standards.<br/>
                        Recognized multiple times by Walter Code Company, with features on platforms like LinkedIn and Medium.<br/>
                      </div>
                    </div>

                    <div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">GTAC - San Francisco(USA) - Full-stack Senior Developer</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                        GTAC (Grand Theft Auto Community) is a comprehensive digital platform primarily tailored for the gaming community but extends its offerings to graphic artists and developers alike. This cohesive environment brings together various Work-In-Progress (WIP) projects to operate seamlessly as a singular product, enhancing user engagement and experience.
                        <br/><br/>
                        Key Features and Components:<br/><br/>

                        Online Social Network: The platform boasts a robust social network ecosystem. Users can engage with dynamic content such as feeds, blogs, forums, and more, fostering community engagement and knowledge sharing.<br/><br/>

                        Artists' Hub: Graphic artists can leverage this platform to showcase their portfolios, providing them with visibility and an opportunity to collaborate with like-minded professionals.<br/><br/>

                        Developers’ Corner: A dedicated space for developers to share their insights, knowledge, and projects. This feature encourages collaboration, innovation, and knowledge dissemination within the developer community.

                        Web Browser Game: A standout component of GTAC is its 3D web browser game developed using Three.js. The game is complemented by high-quality computer graphics and modeling crafted in Blender, providing users with an immersive gaming experience.

                        Online Multiplayer Mod for GTA: San Andreas: In addition to the web browser game, GTAC also offers an online multiplayer modification for the renowned video game, Grand Theft Auto: San Andreas.<br/><br/>

                        Interconnected Experience: One of the platform's distinguishing features is its interconnected functionality. Actions performed within the game server resonate in real-time across the web browser game and the broader GTAC platform, and vice-versa. This multi-functionality ensures a cohesive and interactive user experience across all touchpoints.
                      </div>
                    </div>

                    <div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">2.5D Web Browser Strategy Game Development - San Francisco (USA)</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">

                        Sadin Pita played an instrumental role in the conceptualization and execution of a groundbreaking 2.5D web browser strategy game, tapping into both 2D and 3D JavaScript libraries to sculpt a rich and immersive gaming realm.<br/><br/>

                        Key Accomplishments and Roles:<br/><br/>

                        Frontend Excellence: Harnessing the capabilities of React, Sadin meticulously constructed dynamic user interfaces, ensuring players were greeted with a responsive and captivating gaming environment.<br/><br/>

                        Backend Mastery: Led the design and development of the backend using Node.js and Express, providing the game with a solid foundation. Paired with MySQL, this ensured fluid game mechanics and comprehensive data storage solutions.

                        Artistic Endeavors: Beyond his development prowess, Sadin showcased his multifaceted talent by crafting numerous graphic arts via a graphic tablet. This vivid artwork elevated the game's visual narrative.<br/><br/>

                        Graphics Integration: Leveraged state-of-the-art JavaScript libraries like PixiJS for 2D renditions and Three.js for 3D visualizations. Sadin's expertise ensured these graphics were seamlessly embedded into the gameplay, reinforcing the game's unique 2.5D dimensionality.
                      </div>
                    </div>

                  </div>
                </div>
                <div class="lg:pl-20">
                  <button v-if='1==0' @click='contactActive=true' type="button" class="content-center rounded-md bg-indigo-600 px-10 py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 w-full">
                    Hire Sadin
                  </button>

                  <div role="list">
                    <div class="mt-8 border-t border-zinc-100 pt-8 dark:border-zinc-700/40">
                      <div style="height: 40px;">
                        <span class="tag border-indigo">Node.js</span>
                        <span class="tag border-indigo">PHP</span>
                        <span class="tag border-indigo">Laravel</span>
                        <span class="tag border-indigo">JavaScript</span>
                      </div>
                      <div style="height: 40px;">
                        <span class="tag border-indigo">Vue.js</span>
                        <span class="tag border-indigo">Angular</span>
                        <span class="tag border-indigo">Python</span>
                        <span class="tag border-indigo">SQL</span>
                      </div>
                      <div style="height: 40px;">
                        <span class="tag border-indigo">Three.js</span>
                        <span class="tag border-indigo">jQuery</span>
                        <span class="tag border-indigo">Bootstrap</span>
                        <span class="tag border-indigo">CSS</span>
                      </div>
                      <div style="height: 40px;">
                        <span class="tag border-indigo">React, Redux</span>
                        <span class="tag border-indigo">Restful APIs</span>
                        <span class="tag border-indigo">Git</span>
                      </div>
                      <div style="height: 40px;">
                        <span class="tag border-indigo">HTML</span>
                      </div>
                    </div>
                  </div>

                  <div role="list" class="mt-4">
                    <span class="font-semibold">Languages:</span>
                    <div class="mt-4 border-t border-zinc-100 pt-4 dark:border-zinc-700/40">
                      <div style="height: 40px;">
                        <span class="tag" style="border-color: green; color: green">English</span>
                        <span class="tag" style="border-color: green; color: green">German B2</span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <div v-if='contactActive' class="contact-section">
      <Contact />
    </div>

  </div>
</template>

<script>
import Contact from "@/views/employee/Contact"

export default {
  name: 'Employee-page-sadin',
  components: {
    Contact
  },
  data () {
    return {
      contactActive: false
    }
  },

  methods: {

  },

  mounted () {
    
  }
}
</script>

<style lang="scss" scoped>

.tag {
  margin-right: 15px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #2764EA;
  border-radius: 15px;
  color: #2764EA;
}

.contact-section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.exp {
  margin-top: 10px;
  font-weight: 500;
  color: #2764EA;
}

</style>