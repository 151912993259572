<template>
  <div class="add-payment-section">

   <div class="flex flex-1 flex-col md:pl-64">
     <main class="flex-1">
       <div class="py-6">

         <div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
           
           <div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
             <div class="sm:mx-auto sm:w-full sm:max-w-md">
               <img class="mx-auto h-12 w-auto" src="@/assets/img/logo.png" alt="Your Company">
               <h2 class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Add payment for this month</h2>
               <p class="mt-2 text-center text-sm text-gray-600">
                 <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">Welcome</a>
               </p>
             </div>

             <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
               <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                 <form class="space-y-6" action="#" method="POST">
                   <div style="overflow: auto;">

                    <label for="location" class="block text-sm font-medium text-gray-700">Users</label>
                    <select v-model='payment.user_id' class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                      <option v-for='(user, index) in users' :key='index' :value='user.id'>{{user.name}}</option>
                    </select>

                    <div style="clear: both;"></div>

                    <label class="block text-sm font-medium text-gray-700 mt-2">Pay Period</label>
                    <div class="mt-1">
                     <input v-model='payment.pay_period' type="text" class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm float-left">
                    </div>

                    <div style="clear: both;"></div>

                    <label for="location" class="block text-sm font-medium text-gray-700 mt-1">Salary paid in month</label>
                    <select v-model='payment.month_payed' class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                      <option v-for='(month, index) in months' :key='index'>{{month}}</option>
                    </select>

                    <div style="clear: both;"></div>

                    <label for="location" class="block text-sm font-medium text-gray-700 mt-1">Year</label>
                    <select v-model='payment.year' class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                      <option v-for='(year, index) in years' :key='index'>{{year}}</option>
                    </select>

                    <div style="clear: both;"></div>

                    <label for="days" class="block text-sm font-medium text-gray-700 mt-1">
                      Days worked
                      <div style="float: right;">/ of days</div>
                    </label>
                    <div class="mt-1">
                     <input v-model='payment.days_worked' type="number" min="0" required class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm float-left" style="width: 65%;">
                     <input v-model='payment.total_days' type="text" required class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm float-left" style="width: 30%;margin-left: 5%;" placeholder="total">
                    </div>

                    <div style="clear: both;"></div>

                    <label class="block text-sm font-medium text-gray-700 mt-2">Added Percentage / %</label>
                    <div class="mt-1">
                     <input v-model='payment.added_percentage' type="number" min="0" required class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm float-left">
                    </div>

                    <div style="clear: both;"></div>

                    <label for="days" class="block text-sm font-medium text-gray-700 mt-2">
                      Daily rate in BAM
                      <div style="float: right;">Salary (not accurate)</div>
                    </label>
                    <div class="mt-1">
                     <input v-model='payment.rate' type="number" min="0" required class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm float-left" style="width: 65%">
                     <input :value='payment.rate * payment.days_worked * ((100 + payment.added_percentage) / 100)' type="number" min="0" required class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm float-left" style="width: 30%;margin-left: 5%;">
                    </div>

                    <div style="clear: both;"></div>

                    <label class="block text-sm font-medium text-gray-700 mt-2">Total payment / BAM</label>
                    <div class="mt-1">
                     <input v-model='payment.total' type="number" min="0" required class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm float-left">
                    </div>

                    <div style="clear: both;"></div>

                    <label class="block text-sm font-medium text-gray-700 mt-2">Tax / BAM</label>
                    <div class="mt-1">
                     <input v-model='payment.tax' type="number" min="0" required class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm float-left">
                    </div>

                    <div style="clear: both;"></div>

                    <label class="block text-sm font-medium text-gray-700 mt-2">Expensess (Salary) / BAM, what employee gets</label>
                    <div class="mt-1">
                     <input v-model='payment.expensess' type="number" min="0" required class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm float-left">
                    </div>

                    <div style="clear: both;"></div>

                    <label class="block text-sm font-medium text-gray-700 mt-2">Gain / BAM</label>
                    <div class="mt-1">
                     <input v-model='payment.gain' type="number" min="0" required class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm float-left">
                    </div>

                    <label class="block text-sm font-medium text-gray-700 mt-2">Expected Payment Date</label>
                    <div class="mt-1">
                     <input v-model='payment.expected_payment_date' type="text" class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm float-left">
                    </div>

                    <div style="clear: both;"></div>

                    <label for="location" class="block text-sm font-medium text-gray-700 mt-2">Job Type</label>
                    <select v-model='payment.job_type' class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                      <option v-for='(job, index) in jobTypes' :key='index'>{{job}}</option>
                    </select>

                   </div>

                   <div style="clear: both;"></div>

                   <div class="mt-4">
                     <div @click='createPayment()' class="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" style="cursor: pointer;">Add</div>
                   </div>

                 </form>

               </div>
             </div>
           </div>

         </div>


         <!-- -->

         <div class="px-4 sm:px-6 lg:px-8">
           <div class="sm:flex sm:items-center">
             <div class="sm:flex-auto">
               <h1 class="text-xl font-semibold text-gray-900">Working days per month in a year {{currentYear}}</h1>
               <p class="mt-2 text-sm text-gray-700">A list of working days.</p>
             </div>
           </div>
           <div class="mt-8 flex flex-col">
             <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
               <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                 <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                   <table class="min-w-full divide-y divide-gray-300">
                     <thead class="bg-gray-50">
                       <tr>
                         <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Month</th>
                         <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Working Days</th>
                       </tr>
                     </thead>
                     <tbody class="bg-white">
                       <!-- Odd row -->
                       <tr v-for='(month, index) in workingDaysList' :key='index'>
                         <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{{month.name}}</td>
                         <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{month.workingDays}}</td>
                       </tr>

                       <!-- More people... -->
                     </tbody>
                   </table>
                 </div>
               </div>
             </div>
           </div>
         </div>

       </div>
     </main>
   </div>

  </div>
</template>

<script>
import api from '@/api/api'

export default {
  name: 'Add-payment',
  components: {

  },
  data () {
    return {
      payment: {
        user_id: 1,
        days_worked: 0,
        total_days: 0,
        rate: 0,
        total: 0,
        tax: 0,
        expensess: 0,
        gain: 0,
        added_percentage: 0,
        pay_period: '',
        month_payed: 'January',
        type: 'salary',
        status: 'created',
        job_type: 'programmer',
        expected_payment_date: '',
        year: 2022
      },
      users: null,
      workingDaysList: [],
      currentYear: new Date().getFullYear(),
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      jobTypes: ['programmer', 'qa', 'designer'],
      years: [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030]
    }
  },

  methods: {

    async getUsers () {
      let response = await api.getUsers()
      console.log('response', response)
      if (response.request.status < 300) {
        this.users = response.data
      } else {
        console.log('error')
      }
    },

    async createPayment () {
      let response = await api.createPayment(this.payment)
      console.log('response', response)
      if (response.request.status < 300) {
        this.$toast.success('Payment Added!')
      } else {
        console.log('error')
      }
    },

    getWorkingDaysInMonth(year, month) {
      // create a date object for the last day of the month
      const endDate = new Date(year, month + 1, 0);
      // get the number of days in the month
      const daysInMonth = endDate.getDate();
      // initialize a counter for the number of working days
      let workingDays = 0;
      // loop through each day of the month
      for (let i = 1; i <= daysInMonth; i++) {
        // create a date object for the current day
        const currentDate = new Date(year, month, i);
        // check if the current day is not a weekend day
        if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
        // if it's not a weekend day, increment the counter
          workingDays++;
        }
      }
      // return the number of working days
      return workingDays
    },

    workingDaysYear () {

      for(let i=0; i<= 11; i++) {
        this.workingDaysList.push({ name: this.months[i] , workingDays: this.getWorkingDaysInMonth(this.currentYear, i) })
      }
      console.log('workingDaysList', this.currentYear)
    }

  },

  mounted () {
    this.getUsers()
    this.workingDaysYear()
  }
}
</script>

<style lang="scss" scoped>

  select {
    border: 1px solid #dedede;
  }

</style>