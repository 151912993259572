<template>
  <div class="employee">
    <main class="flex-auto">
      <div class="sm:px-8 mt-16 sm:mt-32">
        <div class="mx-auto w-full max-w-7xl lg:px-8">
          <div class="relative px-4 sm:px-8 lg:px-12">
            <div class="mx-auto max-w-2xl lg:max-w-5xl">
              <div class="grid grid-cols-1 gap-y-16 lg:grid-cols-2 lg:grid-rows-[auto_1fr] lg:gap-y-12">
                <div class="lg:pl-20">
                  <div class="max-w-xs px-2.5 lg:max-w-none">
                    <img alt="" loading="lazy" width="800" height="800" decoding="async" data-nimg="1" class="aspect-square rotate-3 rounded-2xl bg-zinc-100 object-cover dark:bg-zinc-800" sizes="(min-width: 1024px) 32rem, 20rem" src="@/assets/employees/hanad.jpg" style="color: transparent;">
                  </div>
                </div>
                <div class="lg:order-first lg:row-span-2">
                  <h1 class="text-4xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
                    Hanad Bajrambašić, <br/> Senior Full Stack Engineer
                  </h1>

                  <div class="exp">
                    6+ years of professional experience
                  </div>

                  <div class="ver text-sm text-green-600">
                    <div class="mt-1 ">
                      <svg  style='display: inline;margin-top: -3px;' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                      </svg>
                      Verified in Java
                    </div>
                    <div class="mt-0.5">
                      <svg  style='display: inline;margin-top: -3px;' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                      </svg>
                      Verified in Python
                    </div>
                    <div class="mt-0.5">
                      <svg  style='display: inline;margin-top: -3px;' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                      </svg>
                      Verified in Angular, React
                    </div>
                    <div class="mt-0.5">
                      <svg style='display: inline;margin-top: -3px;' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
                      </svg>
                      Team Lead
                    </div>
                  </div>

                  <div class="mt-6 mb-8 space-y-7 text-base text-zinc-600 dark:text-zinc-400">
                    <p>
                      Hanad's story is truly inspiring, especially in the field of technology. His journey began at the University of Sarajevo, where his passion for tech really took off. It wasn't just about getting a degree in Computer Science; for Hanad, it was about diving deep into the subject, understanding its practical applications, and feeding his hunger for knowledge. This is where his path towards becoming a tech leader began.<br/><br/>

                      From the very classrooms of the University of Sarajevo, Hanad's enthusiasm for innovation and technology grew exponentially. He didn't just learn; he explored, questioned, and applied his knowledge in ways that set the stage for his future successes. Now, as a prominent figure in the tech industry, Hanad stands as a testament to what can be achieved with a solid foundation in computer science and an unwavering commitment to excellence.<br/><br/>
                      His transition from a student to a tech innovator is not just a personal victory but an inspiring example for others, showcasing how dedication and a deep understanding of technology can lead to extraordinary accomplishments in this fast-paced and constantly changing field.
                    </p>

                    <div>
                      <div class="relative z-10 order-first mb-3 flex items-center text-normal text-zinc-600 dark:text-zinc-500">
                        <span class="font-semibold">Education</span>
                      </div>
                      <div>
                        <span class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">
                          University of Sarajevo, Bachelor’s degree, Computer Science
                        </span>
                        <br/>
                      </div>
                    </div>

                    <div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">Evolt - Full Stack Engineer and Team Lead</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                        In the realm of learning management systems, Hanad emerged as a leader, skillfully steering a team of 10 developers. His ability to foster clear and effective communication among stakeholders was a testament to his leadership skills. Notably, Hanad pioneered the implementation of a custom authentication server using OAuth, a move that significantly bolstered the system's security.<br/>
                        Key Technologies: Django REST, PostgreSQL, React, Docker<br/><br/>

                        Innovative Contributions to Live Auction Technologies<br/><br/>

                        Hanad demonstrated his innovative prowess by designing and implementing a solution for managing ActiveMQ listeners. His expertise was further highlighted in his handling of ActiveMQ message serialization and deserialization, ensuring seamless data flow and system reliability.<br/>
                        Key Technologies: Spring Boot, PostgreSQL<br/><br/>

                        Revolutionizing the Banking Sector<br/><br/>

                        In the banking domain, Hanad made significant strides by implementing a custom Django JWT authentication scheme, enhancing the platform's security. His development of a state machine for tracking crypto exchanges illustrated his meticulous approach to complex problems. Moreover, his integration of third-party services simplified the processes of bank account creation and debit card issuance. His contribution of a custom encryption solution further fortified the platform's data integrity.<br/>
                        Key Technologies: Django REST, PostgreSQL, React, Docker<br/><br/>

                        Advancing Global Crypto Infrastructure<br/><br/>

                        Hanad played a pivotal role in optimizing cryptocurrency exchange rate tickers using Celery chords, improving operational efficiency. His integration of third-party payment providers expanded the platform's financial capabilities. He also showcased his strategic thinking by refactoring a reporting solution with a Strategy pattern and implementing an action tracking solution with GenericForeignKeys, ensuring detailed monitoring and data accuracy.<br/>
                        Key Technologies: Django REST, PostgreSQL, React, Docker
                      </div>
                    </div>

                    <div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">Pragmatio Solutions - Full Stack Engineer</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                        Hanad delved into the world of AWS serverless architecture, demonstrating an aptitude for creating versatile front-end applications supported by serverless backends. His toolkit included mastering AWS AppSync, DynamoDB, and Cognito, reflecting his commitment to embracing cutting-edge serverless solutions.<br/><br/>

                        Cross-Platform Mobile Development Expertise<br/><br/>

                        With a passion for innovative mobile solutions, Hanad honed his skills in cross-platform mobile development through React Native. His work on 'Smart Reports' stands out as a milestone, marking his first professional foray into a large development team. Here, he skillfully integrated third-party reporting tools, showcasing his ability to blend various technologies seamlessly.<br/><br/>

                        Proficiency in Java EE, MariaDB, and AngularJS<br/><br/>

                        Hanad's journey also took him through the realms of Java Enterprise Edition, MariaDB, and AngularJS. He excelled in developing dynamic web applications that smoothly integrated powerful databases with sophisticated front-end designs.

                        Full-Stack Development and Agile Mastery<br/><br/>

                        The world of full-stack web development was where Hanad truly began to shine. He quickly adapted to managing both front-end and back-end aspects of development. Embracing the SCRUM framework, he not only enhanced his project management skills but also contributed significantly to his team's agile methodology practices.
                      </div>
                    </div>

                  </div>
                </div>
                <div class="lg:pl-20">
                  <button v-if='1==0' @click='contactActive=true' type="button" class="content-center rounded-md bg-indigo-600 px-10 py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 w-full">
                    Hire Hanad
                  </button>

                  <div role="list">
                    <div class="mt-8 border-t border-zinc-100 pt-8 dark:border-zinc-700/40">
                      <div style="height: 40px;">
                        <span class="tag border-indigo">Java</span>
                        <span class="tag border-indigo">Python</span>
                        <span class="tag border-indigo">React</span>
                        <span class="tag border-indigo">Angular</span>
                      </div>
                      <div style="height: 40px;">
                        <span class="tag border-indigo">Node.js</span>
                        <span class="tag border-indigo">React Native</span>
                        <span class="tag border-indigo">Docker</span>
                        <span class="tag border-indigo">Django</span>
                      </div>
                      <div style="height: 40px;">
                        <span class="tag border-indigo">PostgreSQL</span>
                        <span class="tag border-indigo">DynamoDB</span>
                        <span class="tag border-indigo">MySQL</span>
                        <span class="tag border-indigo">Git</span>
                      </div>
                    </div>
                  </div>

                  <div role="list" class="mt-4">
                    <span class="font-semibold">Languages:</span>
                    <div class="mt-4 border-t border-zinc-100 pt-4 dark:border-zinc-700/40">
                      <div style="height: 40px;">
                        <span class="tag" style="border-color: green; color: green">English</span>
                        <span class="tag" style="border-color: green; color: green">Spanish A2</span>
                        <span class="tag" style="border-color: green; color: green">German A1</span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <div v-if='contactActive' class="contact-section">
      <Contact />
    </div>

  </div>
</template>

<script>
import Contact from "@/views/employee/Contact"

export default {
  name: 'Employee-page-hanad',
  components: {
    Contact
  },
  data () {
    return {
      contactActive: false
    }
  },

  methods: {

  },

  mounted () {
    
  }
}
</script>

<style lang="scss" scoped>

.tag {
  margin-right: 15px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #2764EA;
  border-radius: 15px;
  color: #2764EA;
}

.contact-section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.exp {
  margin-top: 10px;
  font-weight: 500;
  color: #2764EA;
}

</style>