<template>
  <div class="report-section">
    
    <div class="flex flex-1 flex-col md:pl-64">
      <main class="flex-1">
        <div class="py-6">

          <div class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
            
            <div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
              <div class="sm:mx-auto sm:w-full sm:max-w-md">
                <img class="mx-auto h-12 w-auto" src="@/assets/img/logo.png" alt="Your Company">
                <h2 class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Add report for this month</h2>
                <p class="mt-2 text-center text-sm text-gray-600">
                  <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">Welcome</a>
                </p>
              </div>

              <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                  <form class="space-y-6" action="#" method="POST">
                    <div style="overflow: auto;">

                      <label for="days" class="block text-sm font-medium text-gray-700">Days worked</label>
                      <div class="mt-1">
                        <input v-model='daysWorked' type="number" min="0" required class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm float-left" style="width: 80%;">
                        <input v-model='daysInMonth' type="text" required class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm float-left text-center" style="width: 15%;margin-left: 5%;" disabled='disabled'>
                      </div>

                      <div style="clear: both;"></div>

                      <label for="days" class="block text-sm font-medium text-gray-700 mt-2">Daily rate in BAM</label>
                      <div class="mt-1">
                        <input v-model='rate' type="number" min="0" required class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm float-left" disabled='disabled'>
                      </div>

                      <div style="clear: both;"></div>

                      <label for="days" class="block text-sm font-medium text-gray-700 mt-2">Salary for this month / BAM</label>
                      <div class="mt-1">
                        <input v-model='salary' type="number" min="0" required class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm float-left" disabled='disabled'>
                      </div>

                    </div>

                    <div style="clear: both;"></div>

                    <div class="mt-4">
                      <div @click='login()' class="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" style="cursor: pointer;">Add</div>
                    </div>

                  </form>

                </div>
              </div>
            </div>

          </div>
        </div>
      </main>
    </div>

  </div>
</template>

<script>
  import api from '@/api/api'

export default {
  name: 'Monthly-report',
  components: {

  },
  data () {
    return {
      daysWorked: 0,
      rate: 0,
      daysInMonth: 0,
      loggedUser: null,
      salary: 0
    }
  },

  watch: {
    daysWorked () {
      this.salary = this.rate * this.daysWorked
      console.log('asdadasdasd')
    }
  },

  methods: {
    getWorkDaysInMonth() {
      // get current month and year
      var currentMonth = new Date().getMonth()
      var currentYear = new Date().getFullYear()

      // create a new date object for the first day of the month
      var firstDay = new Date(currentYear, currentMonth, 1)

      // create a new date object for the last day of the month
      var lastDay = new Date(currentYear, currentMonth + 1, 0)

      // initialize work day count to 0
      var workDays = 0

      // loop through each day of the month
      for (var currentDay = firstDay; currentDay <= lastDay; currentDay.setDate(currentDay.getDate() + 1)) {
      // check if current day is not a weekend day (Saturday or Sunday)
        if (currentDay.getDay() !== 0 && currentDay.getDay() !== 6) {
          workDays++;
        }
      }

      this.daysInMonth = workDays
    },

    calculateSalary () {
      this.salary = this.rate * this.daysWorked
    },

    async me () {
      let response = await api.me()

      console.log('response', response)
      if (response.request.status < 300) {
        this.loggedUser = response.data
        this.rate = parseFloat(this.loggedUser.rate)
        console.log('response', response)
      } else {
        console.log('error')
      }
    }

  },

  mounted () {
    this.getWorkDaysInMonth()
    this.me()
  }
}
</script>

<style lang="scss" scoped>

</style>