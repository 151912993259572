<template>
  <div class="hero-page-section">
    
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pt-20 pb-16 text-center lg:pt-32">
      <h1 class="mx-auto max-w-5xl font-display text-5xl font-medium tracking-tight text-slate-900 sm:text-7xl font-lexend" style="line-height: 79px;">Elevating your
        <!-- -->
        <span class="relative whitespace-nowrap text-blue-600 mb-12">
          <svg aria-hidden="true" viewBox="0 0 418 42" class="absolute top-2/3 left-0 h-[0.58em] w-full fill-blue-300/70" preserveAspectRatio="none">
            <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"></path>
          </svg>
          <span class="relative">web presence</span>
        </span>
        <!-- --> with AI solutions.
      </h1>
      <p class="mx-auto mt-6 max-w-2xl text-lg tracking-tight text-slate-600 font-lexend tracking-tight font-inter text-lg" style="line-height: 26px;font-weight: 300;">The integration of AI into web development enhances user experiences and provides businesses with valuable insights and automation capabilities.</p>
      <div class="mt-8 flex justify-center gap-x-6">
        <a href="/#contact" @click='selectHire()' class="group inline-flex items-center justify-center rounded-full py-3 px-6 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-slate-900 text-white hover:bg-slate-700 hover:text-slate-100 active:bg-slate-800 active:text-slate-300 focus-visible:outline-slate-900" style="font-size: 16px;font-weight: 500; background: #2563EB">Hire Engineer</a>
      </div>
      <div class="mt-36 lg:mt-44">
        <p class="font-display text-base text-slate-900 font-lexend" style="font-weight: 400;">Trusted by these companies</p>
        <ul role="list" class="mt-8 flex items-center justify-center gap-x-8 sm:flex-col sm:gap-x-0 sm:gap-y-10 xl:flex-row xl:gap-x-12 xl:gap-y-0">

          <li>
            <ul role="list" class="flex flex-col items-center gap-y-8 sm:flex-row sm:gap-x-12 sm:gap-y-0">
              <li class="flex">
                <img alt="Transistor" src="@/assets/clients/atmosphere.png" width="158" height="48" decoding="async" data-nimg="1" loading="lazy" style="color:transparent">
              </li>
              <li class="flex">
                <img alt="Tuple" src="@/assets/clients/rarecarat.png" width="158" height="48" decoding="async" data-nimg="1" loading="lazy" style="color:transparent">
              </li>
              <li class="flex">
                <img alt="StaticKit" src="@/assets/clients/medosync.png" width="158" height="48" decoding="async" data-nimg="1" loading="lazy" style="color:transparent">
              </li>
            </ul>
          </li>

          <li>
            <ul role="list" class="flex flex-col items-center gap-y-8 sm:flex-row sm:gap-x-12 sm:gap-y-0">
              <li class="flex">
                <img alt="Mirage" src="@/assets/clients/our.png" width="110" height="38" decoding="async" data-nimg="1" loading="lazy" style="color:transparent">
              </li>
              <li class="flex">
                <img alt="Laravel" src="@/assets/clients/marketing-secrets.png" width="165" height="38" decoding="async" data-nimg="1" loading="lazy" style="color:transparent">
              </li>
              <li class="flex">
                <img alt="Statamic" src="@/assets/clients/keeper.png" width="105" height="38" decoding="async" data-nimg="1" loading="lazy" style="color:transparent">
              </li>
            </ul>
          </li>

          <li>
            <ul role="list" class="flex flex-col items-center gap-y-8 sm:flex-row sm:gap-x-12 sm:gap-y-0">
              <li class="flex">
                <img alt="Transistor" src="@/assets/clients/webprax.png" width="130" height="36" decoding="async" data-nimg="1" loading="lazy" style="color:transparent">
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Header-landing-page',
  components: {

  },
  data () {
    return {

    }
  },

  methods: {
    selectHire () {
      this.$parent.$refs.contact.selectHire()
    }
  },

  mounted () {
    
  }
}
</script>

<style lang="scss" scoped>

  .hero-page-section {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
  }

  .font-lexend {
    font-family: 'Lexend', sans-serif;  
  }

  .font-inter {
    font-family: 'Inter', sans-serif;
  }
  

</style>