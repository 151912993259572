<template>
  <div class="contact-li">
    <div class="relative bg-white">
      <div class="lg:absolute lg:inset-0 lg:left-1/2">
        <img class="h-64 w-full bg-gray-50 object-cover sm:h-80 lg:absolute lg:h-full" src="https://images.unsplash.com/photo-1559136555-9303baea8ebd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-x=.4&w=2560&h=3413&&q=80" alt="">
      </div>
      <div class="pb-24 pt-12 sm:pb-32 sm:pt-12 lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:pt-12">
        <div class="px-6 lg:px-8">
          <div class="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
            <h2 class="text-3xl font-bold tracking-tight text-gray-900">Let's work together</h2>
            <p class="mt-2 text-normal leading-6 text-gray-600">Let's work together and harness our combined expertise. By uniting our strengths, we can pave the way for innovation and achieve exceptional results.</p>
            <div class="mt-16">
              <div class="grid gap-y-6 sm:grid-cols-2">
                <div class="sm:col-span-2">
                  <label for="name" class="block text-sm font-semibold leading-6 text-gray-900">Name</label>
                  <div class="mt-2.5">
                    <input v-model='contact.name' type="text" name="name" id="name" autocomplete="organization" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                  </div>
                </div>
                <div class="sm:col-span-2">
                  <label for="email" class="block text-sm font-semibold leading-6 text-gray-900">Email</label>
                  <div class="mt-2.5">
                    <input v-model='contact.email' id="email" name="email" type="email" autocomplete="email" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                  </div>
                </div>
                <div class="sm:col-span-2">
                  <label for="company" class="block text-sm font-semibold leading-6 text-gray-900">Company</label>
                  <div class="mt-2.5">
                    <input v-model='contact.company' type="text" name="company" id="company" autocomplete="organization" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                  </div>
                </div>
                <div class="sm:col-span-2">
                  <div class="flex justify-between text-sm leading-6">
                    <label for="phone" class="block font-semibold text-gray-900">Phone</label>
                    <p id="phone-description" class="text-gray-400">Optional</p>
                  </div>
                  <div class="mt-2.5">
                    <input v-model='contact.phone' type="tel" name="phone" id="phone" autocomplete="tel" aria-describedby="phone-description" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                  </div>
                </div>
                <div class="sm:col-span-2">
                  <div class="flex justify-between text-sm leading-6">
                    <label for="message" class="block text-sm font-semibold leading-6 text-gray-900">How can we help you?</label>
                    <p id="message-description" class="text-gray-400">Max 500 characters</p>
                  </div>
                  <div class="mt-2.5">
                    <textarea v-model='contact.message' id="message" name="message" rows="4" aria-describedby="message-description" class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
                  </div>
                </div>
                <fieldset class="sm:col-span-2">
                  <legend class="block text-sm font-semibold leading-6 text-gray-900 text-center">Thank you for reaching out. We have received your message and will get back to you as soon as we can. <br/>
                  We appreciate your patience and look forward working with you.</legend>
                </fieldset>
              </div>
              <div class="mt-10 flex justify-end border-t border-gray-900/10 pt-8">
                <div @click='closeContact()' class="rounded-md bg-red-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mr-5">
                  Close
                </div>
                <div @click="addMessage()" class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  Send message
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/api'

export default {
  name: 'Contact-modal',
  components: {

  },
  data () {
    return {
      contact: {
        name: '',
        email: '',
        phone: '',
        company: '',
        reason: 'DIRECT HIRE',
        message: '',
      }
    }
  },

  methods: {
    async addMessage () {

      if (this.contact.email == '') {
        this.$toast.error('Please add your email.')
        return
      }

      if (this.validateEmail()) {
        this.$toast.error('Please enter a valid email.')
        return
      }

      if (this.contact.message == '') {
        this.$toast.error('Please add your message.')
        return
      }

      let response = await api.addMessage(this.contact)
      console.log('MESSAGE', response)
      if (response.request.status < 300) {
        this.$toast.success('Thanks for contacting us! We will get in touch with you shortly.')
        this.resetMessage()
        this.closeContact()
      } else {
        console.log('error')
      }
    },

    validateEmail() {
      const emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
      return !emailPattern.test(this.contact.email)
    },

    resetMessage () {
      this.contact.name = ''
      this.contact.email = ''
      this.contact.phone = ''
      this.contact.company = ''
      this.contact.reason = 'Get in touch with us'
      this.contact.message = ''
    },

    closeContact () {
      this.$parent.contactActive = false
    }
  },

  mounted () {
    
  }
}
</script>

<style lang="scss" scoped>

</style>