<template>
    <div class="login-section">

      <div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
          <img class="mx-auto h-12 w-auto" src="@/assets/img/logo.png" alt="Your Company">
          <h2 class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Sign in to your account</h2>
          <p class="mt-2 text-center text-sm text-gray-600">
            <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">Welcome</a>
          </p>
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form class="space-y-6" action="#" method="POST">
              <div>
                <label for="email" class="block text-sm font-medium text-gray-700">Email address</label>
                <div class="mt-1">
                  <input v-model='user.email' type="email" autocomplete="email" required class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                </div>
              </div>

              <div>
                <label for="password" class="block text-sm font-medium text-gray-700">Password</label>
                <div class="mt-1">
                  <input v-model='user.password' type="password" autocomplete="current-password" required class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                </div>
              </div>

              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <input id="remember-me" name="remember-me" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500">
                  <label for="remember-me" class="ml-2 block text-sm text-gray-900">Remember me</label>
                </div>

              </div>

              <div>
                <div @click='login()' class="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  Sign in
                </div>
                <a class="inline-block rounded-lg py-1 px-2 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900 text-center w-full mt-3" href="/">Return to Landing page</a>
              </div>
            </form>

          </div>
        </div>
      </div>


    </div>
</template>

<script>
import api from '@/api/api'

export default {
  name: 'Login-view',
  components: {

  },
  data () {
    return {
      user: {
        email: '',
        password: ''
      }
    }
  },

  methods: {
    async login () {
      if (this.user.email && this.user.password) {
        let response = await api.login(this.user)
        console.log('response', response)
        if (response.request.status < 300) {
          localStorage.user = JSON.stringify(response.data.user)
          localStorage._token = response.data.authorisation.token
          this.$toast.success('Welcome')
          if (response.data.user.project == 'zima') {
            window.location.href = "/dashboard"
          }
          if (response.data.user.project == 'deluxe') {
            window.location.href = "/car-detail-shop/expenses"
          }
        } else {
          console.log('error')
        }
      }
    }
  },

  mounted () {
    
  }
}
</script>

<style lang="scss" scoped>

  .login-section {
    margin: auto;
    min-height: 100vh;
    background: #F9FAFB;
  }

</style>