<template>
  <div class="employee">
    <main class="flex-auto">
      <div class="sm:px-8 mt-16 sm:mt-32">
        <div class="mx-auto w-full max-w-7xl lg:px-8">
          <div class="relative px-4 sm:px-8 lg:px-12">
            <div class="mx-auto max-w-2xl lg:max-w-5xl">
              <div class="grid grid-cols-1 gap-y-16 lg:grid-cols-2 lg:grid-rows-[auto_1fr] lg:gap-y-12">
                <div class="lg:pl-20">
                  <div class="max-w-xs px-2.5 lg:max-w-none">
                    <img alt="" loading="lazy" width="800" height="800" decoding="async" data-nimg="1" class="aspect-square rotate-3 rounded-2xl bg-zinc-100 object-cover dark:bg-zinc-800" sizes="(min-width: 1024px) 32rem, 20rem" src="@/assets/employees/amar.jpg" style="color: transparent;">
                  </div>
                </div>
                <div class="lg:order-first lg:row-span-2">
                  <h1 class="text-4xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
                    Amar Čorbo, <br/> QA Automation Engineer, QA analyst.
                  </h1>

                  <div class="exp">
                    5+ years of professional experience
                  </div>

                  <div class="ver text-sm text-green-600">
                    <div class="mt-1 ">
                      <svg  style='display: inline;margin-top: -3px;' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                      </svg>
                      Verified as QA Analyst
                    </div>
                    <div class="mt-0.5">
                      <svg  style='display: inline;margin-top: -3px;' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                      </svg>
                      Verified as QA Automation
                    </div>
                    <div class="mt-0.5">
                      <svg style='display: inline;margin-top: -3px;' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
                      </svg>
                      Team Lead
                    </div>
                  </div>

                  <div class="mt-6 mb-8 space-y-7 text-base text-zinc-600 dark:text-zinc-400">
                    <p>
                      I'm Amar Čorbo, and I've been lucky enough to turn my passion for technology into a career that's as diverse as it is rewarding. Currently, I'm the QA Team Lead at Authority Partners, but my journey to this point has been anything but linear – it's been an adventure through different roles and challenges.
                      <br/><br/>

                      In sunny Irvine, California, I lead a team of talented individuals at Authority Partners. Here, my days are filled with the nitty-gritty of ensuring software perfection in our Valutrust program. It's a role that constantly challenges me, but also one that I relish. I'm not just checking boxes; I'm mentoring, strategizing, and leading a team dedicated to making sure our software is the best it can be.
                      <br/><br/>

                      Before settling into my current role, I dipped my toes into software development. This was a time of intense learning and growth, where I got to put my problem-solving skills to the test and contribute to crafting innovative software solutions.
                      <br/><br/>

                      I've also experienced the fast-paced world of customer care with DDC MLS in Sarajevo. There, I was the bridge between technology and people, helping users in the UK navigate their tech challenges. It taught me a lot about patience, clear communication, and the importance of understanding the user's perspective.
                      <br/><br/>

                      My professional life is a collage of different roles – from QA and software development to customer service and even a bit of event management. Each role has been a chapter in my story, teaching me something valuable. As I continue on this tech adventure, my love for learning, solving problems, and pushing boundaries keeps growing.
                      <br/><br/>
                    </p>

                    <div>
                      <div class="relative z-10 order-first mb-3 flex items-center text-normal text-zinc-600 dark:text-zinc-500">
                        <span class="font-semibold">Education</span>
                      </div>
                      <div>
                        <span class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">
                          Faculty of Natural Sciences and Mathematics, University of Sarajevo
                        </span>
                        <br/>
                      </div>
                    </div>

                    <div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">Zima Blue Software - QA Analyst and Team Lead</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                        QA Analyst and Team Lead at Zima Blue Software<br/><br/>

                        I had the great opportunity to lead the quality assurance side of things for a project called 'Mommnt' at Zima Blue Software. My job was all about making sure our software was top-notch and worked like a charm. I was in charge of setting up all the automation for our testing processes, and I got my hands dirty writing lots of tests to catch any bugs.
                        <br/><br/>
                        One of the big parts of my job was to organize and lead our QA team. It wasn't just about finding problems in the software; it was about teaching my team, figuring out the best ways to work together, and making sure we were always on track and doing our best. I had to keep up with the latest in testing methods, both manual and automated, to make sure we were doing things the smartest way possible.
                        <br/><br/>
                        This place was really something special. It was all about pushing boundaries and being the best at what we do. Being part of Zima Blue Software meant I could really put my skills to the test and help create something people would love. It wasn't just a job; it was about being part of a team that cared about making great software and doing it right.
                        <br/><br/>
                        In this role, I didn't just improve my technical skills in testing; I also grew a lot as a team leader. I learned so much about managing projects, leading a team, and making sure we all succeeded together. It was an amazing experience being the QA Analyst and Team Lead at Zima Blue Software.
                      </div>
                    </div>

                    <div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">Authority Partners, - QA Analyst and Team Lead</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                        Occupation/Position: QA Team Lead<br/><br/>

                        Role and Responsibilities: As a QA Team Lead at Authority Partners, I spearheaded the quality assurance process for the Valutrust program. My role encompassed a broad spectrum of responsibilities including the strategic planning and execution of testing methodologies, ensuring the highest standards of software quality. My leadership extended to mentoring and managing a dedicated team of QA analysts, fostering an environment of continuous learning and improvement. I was responsible for coordinating with cross-functional teams to align quality assurance strategies with overall project goals, ensuring seamless integration and functionality.<br/><br/>

                        Industry: Authority Partners is a renowned software company, known for its commitment to delivering cutting-edge technology solutions across various sectors. As a QA Team Lead within this dynamic and innovative environment, I was able to contribute significantly to the company's reputation for quality and excellence.<br/><br/>

                        Occupation/Position: Software Developer<br/><br/>

                        Role and Responsibilities: In my role as a Software Developer at Authority Partners, I was deeply involved in the development of the APMS program. My responsibilities included designing, coding, testing, and deploying software applications in alignment with client needs and project specifications. This position required a high level of technical proficiency, problem-solving skills, and the ability to work collaboratively with a team of developers and other stakeholders. I played a key role in various phases of the software development lifecycle, from conceptualization to implementation, ensuring that all solutions were scalable, efficient, and met the high-quality standards set by Authority Partners.<br/><br/>

                        Industry: Within the software sector, Authority Partners stands out as a leader in providing innovative and reliable software solutions. My tenure as a Software Developer in this vibrant company allowed me to contribute to and learn from a culture that values technological advancement and customer satisfaction.<br/><br/>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="lg:pl-20">
                  <button v-if='1==0' @click='contactActive=true' type="button" class="content-center rounded-md bg-indigo-600 px-10 py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 w-full">
                    Hire Amar
                  </button>

                  <div role="list">
                    <div class="mt-8 border-t border-zinc-100 pt-8 dark:border-zinc-700/40">
                      <div style="height: 40px;">
                        <span class="tag border-indigo">Java,</span>
                        <span class="tag border-indigo">C# &.NET</span>
                        <span class="tag border-indigo">Python,</span>
                        <span class="tag border-indigo">Javascript</span>
                      </div>
                      <div style="height: 40px;">
                        <span class="tag border-indigo">Test Automation</span>
                        <span class="tag border-indigo">Critical Thinking</span>
                        <span class="tag border-indigo">Git</span>
                      </div>
                      <div style="height: 40px;">
                        <span class="tag border-indigo">Problem Solving</span>
                        <span class="tag border-indigo">Analytical Thinking</span>
                      </div>
                    </div>
                  </div>

                  <div role="list" class="mt-4">
                    <span class="font-semibold">Languages:</span>
                    <div class="mt-4 border-t border-zinc-100 pt-4 dark:border-zinc-700/40">
                      <div style="height: 40px;">
                        <span class="tag" style="border-color: green; color: green">English</span>
                        <span class="tag" style="border-color: green; color: green">Spanish A2</span>
                        <span class="tag" style="border-color: green; color: green">German A1</span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <div v-if='contactActive' class="contact-section">
      <Contact />
    </div>

  </div>
</template>

<script>
import Contact from "@/views/employee/Contact"

export default {
  name: 'Employee-page-amar',
  components: {
    Contact
  },
  data () {
    return {
      contactActive: false
    }
  },

  methods: {

  },

  mounted () {
    
  }
}
</script>

<style lang="scss" scoped>

.tag {
  margin-right: 15px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #2764EA;
  border-radius: 15px;
  color: #2764EA;
}

.contact-section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.exp {
  margin-top: 10px;
  font-weight: 500;
  color: #2764EA;
}

</style>