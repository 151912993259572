<template>
  <Navigation />
  <router-view/>
</template>

<script>
import Navigation from '@/components/Navigation'

export default {
  components: {
    Navigation
  },
  data () {
    return {

    }
  },

  methods: {

  },

  mounted () {
    
  }
}

</script>

<style lang="scss">

</style>
