<template>
  <div class="faq">
    
    <section id="faq" aria-labelledby="faq-title" class="relative overflow-hidden bg-slate-50 py-20 sm:py-32">
      <img alt="" src="@/assets/background-faqs.jpg" width="1558" height="946" decoding="async" data-nimg="1" class="absolute top-0 left-1/2 max-w-none translate-x-[-30%] -translate-y-1/4" loading="lazy" style="color:transparent">

      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative">

        <div class="mx-auto max-w-2xl lg:mx-0">
          <h2 id="faq-title" class="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl font-lexend">
            Frequently asked questions
          </h2>
          <p class="mt-4 text-lg tracking-tight text-slate-700">
          If you can’t find what you’re looking for, email our support team and if you’re lucky someone will get back to you.
          </p>
        </div>

        <ul role="list" class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3">
          <li>
            <ul role="list" class="flex flex-col gap-y-8">
              <li>
                <h3 class="font-display text-normal leading-7 text-slate-900 font-semibold font-lexend">
                  What services do you offer as a software development company?
                </h3>
                <p class="mt-4 text-sm text-slate-700">
                  We offer a wide range of services, including custom software development, mobile app development, web development, cloud computing, UI/UX design, software testing, and maintenance.
                </p>
              </li>

              <li>
                <h3 class="font-display text-normal leading-7 text-slate-900 font-semibold font-lexend">
                  How do you ensure the quality of the software you develop?
                </h3>
                <p class="mt-4 text-sm text-slate-700">
                  We follow industry best practices and employ a rigorous quality assurance process that includes code reviews, continuous integration, automated and manual testing, and performance optimization to ensure high-quality software.
                </p>
              </li>

              <li>
                <h3 class="font-display text-normal leading-7 text-slate-900 font-semibold font-lexend">
                  How do you manage communication and collaboration during the project?
                </h3>
                <p class="mt-4 text-sm text-slate-700">
                  We use project management and communication tools like Jira, Trello, Slack, and Microsoft Teams to ensure smooth collaboration. We also provide regular updates, schedule calls or meetings, and share progress reports based on the agreed communication plan.
                </p>
              </li>

            </ul>
          </li>

          <li>
            <ul role="list" class="flex flex-col gap-y-8">

              <li>
                <h3 class="font-display text-normal leading-7 text-slate-900 font-semibold font-lexend">
                  How do you handle intellectual property and confidentiality?
                </h3>
                <p class="mt-4 text-sm text-slate-700">
                  We sign a Non-Disclosure Agreement (NDA) to ensure the confidentiality of your project. All intellectual property rights, including source code and documentation, are transferred to you upon project completion.
                </p>
              </li>

              <li>
                <h3 class="font-display text-normal leading-7 text-slate-900 font-semibold font-lexend">
                  What are your pricing models?
                </h3>
                <p class="mt-4 text-sm text-slate-700">
                  We offer different pricing models, such as fixed-price, time and material, and dedicated team models, depending on the project scope and requirements. We can discuss the most suitable model for your project during the initial consultation.
                </p>
              </li>

              <li>
                <h3 class="font-display text-normal leading-7 text-slate-900 font-semibold font-lexend">
                  How long does it take to develop a software project?
                </h3>
                <p class="mt-4 text-sm text-slate-700">
                  The development timeline depends on the project's complexity, scope, and requirements. After analyzing your project, we can provide an estimated timeline for completion.
                </p>
              </li>

            </ul>
          </li>

          <li>
            <ul role="list" class="flex flex-col gap-y-8">

              <li>
                <h3 class="font-display text-normal leading-7 text-slate-900 font-semibold font-lexend">
                  Do you provide ongoing support and maintenance after project completion?
                </h3>
                <p class="mt-4 text-sm text-slate-700">
                  Yes, we offer post-launch support and maintenance services to ensure the smooth operation of your software. This may include bug fixes, updates, performance optimization, and new feature implementation.
                </p>
              </li>

              <li>
                <h3 class="font-display text-normal leading-7 text-slate-900 font-semibold font-lexend">
                  How can I get started with my software development project?
                </h3>
                <p class="mt-4 text-sm text-slate-700">
                  Contact us to schedule a consultation. Our team will discuss your project requirements, goals, and expectations, and then provide a detailed proposal outlining the scope, timeline, and cost of the project.
                </p>
              </li>

              <li>
                <h3 class="font-display text-normal leading-7 text-slate-900 font-semibold font-lexend">
                  How do you ensure the quality of your software?
                </h3>
                <p class="mt-4 text-sm text-slate-700">
                  We follow best practices in software development and have a dedicated quality assurance (QA) team responsible for conducting thorough testing throughout the development process. This helps us identify and fix any issues before delivering the final product.
                </p>
              </li>

            </ul>
          </li>

        </ul>

      </div>

    </section>

  </div>
</template>

<script>

export default {
  name: 'Faq-section',
  components: {

  },
  data () {
    return {

    }
  },

  methods: {

  },

  mounted () {
    
  }
}
</script>

<style lang="scss" scoped>

  .font-lexend {
    font-family: 'Lexend', sans-serif;  
  }

  .font-inter {
    font-family: 'Inter', sans-serif;
  }

</style>