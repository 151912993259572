<template>
  <div class="footer">
    
    <footer class="bg-slate-50"><div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"><div class="py-16"><img alt="" srcset="@/assets/logos/logo-blue.png" src="@/assets/logos/logo-blue.png" width="880" height="304" decoding="async" data-nimg="1" class="mx-auto h-10 w-auto" loading="lazy" style="color:transparent"><nav class="mt-10 text-sm" aria-label="quick links"><div class="-my-1 flex justify-center gap-x-6"><a class="inline-block rounded-lg py-1 px-2 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900" href="/#projects">Projects</a><a class="inline-block rounded-lg py-1 px-2 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900" href="/#testimonials">Testimonials</a><a class="inline-block rounded-lg py-1 px-2 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900" href="/#people">People</a></div></nav></div><div class="flex flex-col items-center border-t border-slate-400/10 py-10 sm:flex-row-reverse sm:justify-between">
    <div class="flex gap-x-6 -mt-2">
    <a class="group" aria-label="TaxPal on GitHub" target="_blank" href="https://www.instagram.com/zimablue.ai/">
      <i class="fa-brands fa-instagram text-xl text-slate-500 group-hover:text-slate-700"></i>
    </a>
    <a class="group" aria-label="Zima Blue Software on Facebook" target="_blank" href="https://www.facebook.com/ZimaBlueSoftware">
      <i class="fa-brands fa-facebook text-xl text-slate-500 group-hover:text-slate-700"></i>
    </a>
    <a class="group" aria-label="TaxPal on GitHub" target="_blank" href="https://www.linkedin.com/company/zima-blue-software/">
      <i class="fa-brands fa-linkedin-in text-xl text-slate-500 group-hover:text-slate-700"></i>
    </a>
  </div>
  <p class="mt-6 text-sm text-slate-500 sm:mt-0">Copyright © <!-- -->{{new Date().getFullYear()}}<!-- --> Zima Blue Software. All rights reserved.</p>
  </div>
  </div>
  </footer>

  </div>
</template>

<script>

export default {
  name: 'Footer-section',
  components: {

  },
  data () {
    return {

    }
  },

  methods: {

  },

  mounted () {
    
  }
}
</script>

<style lang="scss" scoped>

</style>