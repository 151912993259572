import { createRouter, createWebHistory } from 'vue-router'
// landing page
import LandingPage from '@/views/landing/Index'
// auth
import Login from '@/views/auth/Login'
import Register from '@/views/auth/Register'
// dashboard
import Dashboard from '@/views/dashboard/Dashboard'
import Report from '@/views/dashboard/Report'
import partnerProgram from '@/views/partner/Program'
//employee
import Sita from '@/views/employee/Sita'
import Emir from '@/views/employee/Emir'
import Vedad from '@/views/employee/Vedad'
import Nahid from '@/views/employee/Nahid'
import Sadin from '@/views/employee/Sadin'
import Senad from '@/views/employee/Senad'
import Amar from '@/views/employee/Amar'
import Amina from '@/views/employee/Amina'
import Hanad from '@/views/employee/Hanad'
import Ivica from '@/views/employee/Ivica'
import Admir from '@/views/employee/Admir'
// admin
import AddPayment from '@/views/admin/AddPayment'

// Car Detail Shop
import Expenses from '@/views/CarDetailShop/Expenses'

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  // dashboard
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/monthly-report',
    name: 'AddPayment',
    component: Report
  },
  {
    path: '/partner',
    name: 'partnerProgram',
    component: partnerProgram
  },
  {
    path: '/employee/edin',
    name: 'Sita',
    component: Sita
  },
  {
    path: '/employee/emir',
    name: 'Emir',
    component: Emir
  },
  {
    path: '/employee/vedad',
    name: 'Vedad',
    component: Vedad
  },
  {
    path: '/employee/nahid',
    name: 'Nahid',
    component: Nahid
  },
  {
    path: '/employee/sadin',
    name: 'Sadin',
    component: Sadin
  },
  {
    path: '/employee/senad',
    name: 'Senad',
    component: Senad
  },
  {
    path: '/employee/amar',
    name: 'Amar',
    component: Amar
  },
  {
    path: '/employee/amina',
    name: 'Amina',
    component: Amina
  },
  {
    path: '/employee/hanad',
    name: 'Hanad',
    component: Hanad
  },
  {
    path: '/employee/ivica',
    name: 'Ivica',
    component: Ivica
  },
  {
    path: '/employee/admir',
    name: 'Admir',
    component: Admir
  },
  // admin
  {
    path: '/add-payment',
    name: 'AddPayment',
    component: AddPayment
  },

  // car detail shop
  {
    path: '/car-detail-shop/expenses',
    name: 'Expenses',
    component: Expenses
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
