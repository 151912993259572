import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

import Toaster from '@meforma/vue-toaster';

//create app
const app = createApp(App).use(store).use(router).use(Toaster)

//add axios
app.config.globalProperties.axios=axios

//mount app
app.mount('#app')