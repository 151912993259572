<template>
  <div class="employee">
    <main class="flex-auto">
      <div class="sm:px-8 mt-16 sm:mt-32">
        <div class="mx-auto w-full max-w-7xl lg:px-8">
          <div class="relative px-4 sm:px-8 lg:px-12">
            <div class="mx-auto max-w-2xl lg:max-w-5xl">
              <div class="grid grid-cols-1 gap-y-16 lg:grid-cols-2 lg:grid-rows-[auto_1fr] lg:gap-y-12">
                <div class="lg:pl-20">
                  <div class="max-w-xs px-2.5 lg:max-w-none">
                    <img alt="" loading="lazy" width="800" height="800" decoding="async" data-nimg="1" class="aspect-square rotate-3 rounded-2xl bg-zinc-100 object-cover dark:bg-zinc-800" sizes="(min-width: 1024px) 32rem, 20rem" src="@/assets/employees/amina.jpg" style="color: transparent;">
                  </div>
                </div>
                <div class="lg:order-first lg:row-span-2">
                  <h1 class="text-4xl font-bold tracking-tight text-zinc-800 dark:text-zinc-100 sm:text-5xl">
                    Amina Sinanović, <br/> Senior Software Engineer.
                  </h1>

                  <div class="exp">
                    4+ years of professional experience
                  </div>

                  <div class="ver text-sm text-green-600">
                    <div class="mt-1 ">
                      <svg  style='display: inline;margin-top: -3px;' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                      </svg>
                      Verified in Java
                    </div>
                    <div class="mt-1 ">
                      <svg  style='display: inline;margin-top: -3px;' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                      </svg>
                      Verified in Python
                    </div>
                    <div class="mt-0.5">
                      <svg  style='display: inline;margin-top: -3px;' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                      </svg>
                      Verified as Data Science
                    </div>
                    <div class="mt-0.5">
                      <svg style='display: inline;margin-top: -3px;' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
                      </svg>
                      Team Lead
                    </div>
                    <div class="mt-0.5" style="color: #e9c607;">
                      <svg style='display: inline;margin-top: -3px;color: #e9c607;' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" data-slot="icon" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5" />
                      </svg>
                      Recipient of Golden Badge of Excellence
                    </div>
                  </div>

                  <div class="mt-6 mb-8 space-y-7 text-base text-zinc-600 dark:text-zinc-400">
                    <p>
                      Amina is a software engineer with an impressive four and a half years of experience. She has navigated through various projects, adeptly handling both monolithic and microservices architectures. <br/>Her technical prowess extends across Java, Spring, MySQL, Python, Django, Kafka, and Node.js. <br/><br/>Known for her technology-agnostic approach, she is adept at thinking outside the box, crafting smart and practical solutions in complex technological landscapes.
                    </p>

                    <div>
                      <div class="relative z-10 order-first mb-3 flex items-center text-normal text-zinc-600 dark:text-zinc-500">
                        <span class="font-semibold">Education</span>
                      </div>
                      <div>
                        <span class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">
                          Theoretical Computer Science, Faculty of Science and Mathematics, University of Sarajevo<br/>
                          <span class='text-sm'>Recipient of Golden Badge of Excellence</span>
                        </span>
                        <br/>
                      </div>
                    </div>

                    <div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">Senior Software engineer, Evolt</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                        Amina demonstrated her comprehensive expertise in systems architecture by navigating the intricacies of a legacy system and successfully leading its transformation into a cutting-edge microservices architecture. Her involvement spanned the entire migration process, showcasing her adeptness in both planning and execution.<br/><br/>
                        In her role, she made significant contributions to the backend development of an e-commerce project, expertly utilizing Node.js and MySQL technologies. Her work in this area was pivotal in enhancing the project's functionality and efficiency.
                        <br/><br/>
                        Amina's leadership was instrumental in guiding a team of seven professionals through a major system overhaul. She adeptly managed the transition from a traditional monolithic structure to a more dynamic microservices architecture, incorporating Django and PostgreSQL. Her strategic direction and technical insight were key to the project's success.
                        <br/><br/>
                        She executed seamless database migrations, employing Kafka and Debezium to ensure continuous data streaming between databases. Her meticulous approach maintained data integrity and system stability throughout the transition, underscoring her capability in handling complex technical challenges.
                        <br/><br/>
                        Her role extended beyond technical implementation; Amina was responsible for maintaining alignment with project objectives, managing stakeholder expectations, and ensuring that the new architecture met both current and future business needs.
                        <br/><br/>
                        Amina's contributions reflect a deep understanding of both the technical and strategic aspects of system migration, positioning her as a valuable asset in any complex IT infrastructure transformation project. Her ability to balance technical prowess with effective team leadership and project management skills sets her apart as a distinguished professional in her field.
                      </div>
                    </div>

                    <div>
                      <div class="text-base font-semibold tracking-tight text-zinc-800 dark:text-zinc-100">Software engineer, Sarasoft</div>
                      <div class="relative z-10 mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                        Amina masterfully developed and implemented a suite of new features for an internal healthcare project, skillfully addressing critical user needs while navigating the complexities of sophisticated business logic. Her contributions significantly enhanced user experience and operational efficiency.<br/><br/>
                        She was instrumental in refining the overall system architecture, demonstrating her expertise in a comprehensive technology stack that included Java, Spring, Hibernate, MySQL, Ext.js, and Android. Her technical acumen played a pivotal role in elevating the system's functionality and reliability.<br/><br/>
                        In a noteworthy initiative, Amina integrated Google's ORTools to optimize routing for mobile healthcare workers. This strategic implementation revolutionized planning and navigation processes, significantly reducing response times and improving service delivery in critical healthcare operations.<br/><br/>
                        Her foresight in identifying key technological solutions and her ability to implement them effectively showcases her profound understanding of the intersection between technology and healthcare services. This has not only improved the workflow but also enhanced the overall impact of the healthcare project.<br/><br/>
                        Amina's contributions extend beyond mere technical implementation; they encompass a holistic approach to system development, ensuring that both the immediate and long-term needs of users and the business are met with precision and foresight.
                      </div>
                    </div>

                  </div>
                </div>
                <div class="lg:pl-20">
                  <button v-if='1==0' @click='contactActive=true' type="button" class="content-center rounded-md bg-indigo-600 px-10 py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 w-full">
                    Hire Amina
                  </button>

                  <div role="list">
                    <div class="mt-8 border-t border-zinc-100 pt-8 dark:border-zinc-700/40">
                      <div style="height: 40px;">
                        <span class="tag border-indigo">Java</span>
                        <span class="tag border-indigo">Node.js</span>
                        <span class="tag border-indigo">Docker</span>
                        <span class="tag border-indigo">Kafka</span>
                      </div>
                      <div style="height: 40px;">
                        <span class="tag border-indigo">MySQL</span>
                        <span class="tag border-indigo">C++</span>
                        <span class="tag border-indigo">Spring</span>
                        <span class="tag border-indigo">Git</span>
                      </div>
                      <div style="height: 40px;">
                        <span class="tag border-indigo">Python</span>
                        <span class="tag border-indigo">Algorithms</span>
                        <span class="tag border-indigo">Data Structures</span>
                      </div>
                      <div style="height: 40px;">
                        <span class="tag border-indigo">Strong Analytical and Critical Thinking</span>
                      </div>
                      <div style="height: 40px;">
                        <span class="tag border-indigo">Problem Solving</span>
                        <span class="tag border-indigo">Leadership</span>
                      </div>
                    </div>
                  </div>

                  <div role="list" class="mt-4">
                    <span class="font-semibold">Languages:</span>
                    <div class="mt-4 border-t border-zinc-100 pt-4 dark:border-zinc-700/40">
                      <div style="height: 40px;">
                        <span class="tag" style="border-color: green; color: green">English</span>
                        <span class="tag" style="border-color: green; color: green">Spanish A1</span>
                        <span class="tag" style="border-color: green; color: green">German A1</span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <div v-if='contactActive' class="contact-section">
      <Contact />
    </div>

  </div>
</template>

<script>
import Contact from "@/views/employee/Contact"

export default {
  name: 'Employee-page-amina',
  components: {
    Contact
  },
  data () {
    return {
      contactActive: false
    }
  },

  methods: {

  },

  mounted () {
    
  }
}
</script>

<style lang="scss" scoped>

.tag {
  margin-right: 15px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #2764EA;
  border-radius: 15px;
  color: #2764EA;
}

.contact-section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.exp {
  margin-top: 10px;
  font-weight: 500;
  color: #2764EA;
}

</style>